.userExhaustModalMainDiv {
  .ant-modal-header {
    margin-bottom: 14px !important;
    .ant-modal-title {
      color: var(--blues-blue-900, #071135) !important;

      /* Heading/H3 */
      font-family: Arial !important;
      font-size: 24px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal !important;
    }
  }
  .ant-modal-content{
    svg{
        color: #071135 !important;
    }
  }
  .desc {
    color: var(--Text-Primary, #071135);

    /* Body text/B1 */
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
