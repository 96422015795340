.onboarding_form_container {
  height: calc(100vh - 85px);
  overflow: auto;
  scrollbar-width: none;
  padding: 24px;
  .Onboarding-sm {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar {
    width: 0px; /* For WebKit-based browsers (Chrome, Safari) */
  }
  .onboard-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .para-desc {
    max-width: 512px;
    text-align: center;
  }
  .progress-container {
    padding: 10px;

    border-radius: 8px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
  }
  .onboarding_form {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    padding: 24px 24px 4px 24px;
    display: flex;
    justify-content: space-between;
    margin-top: 102px;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: #091647 !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
    & label {
      color: #071135;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-input {
      color: #091647 !important;

      /* Body text/B2 */
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper
      .ant-upload.ant-upload-select {
      width: 100% !important;
      height: 72px !important;
      background-color: #f3f5f9 !important;
      border: 1px dashed #1638b1 !important;
      border-radius: 8px;
    }
    .marketing_permission {
      margin-top: 30px;
    }
    @media screen and (max-width: 767px) {
      .marketing_permission {
        margin-top: 0px;
      }
      margin-top: 30px;
    }
  }

  .dragger-upload-content {
    display: flex;
    gap: 8px;
    justify-content: center;
    span {
      color: var(--text-secondary, #1638b1);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
    }
  }

  .marketing_permisssion_text {
    color: var(--text-tertiary, #a7aebb);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btn_holder {
    margin-top: 16px;
    display: flex;
    align-items: end;
    justify-content: end;
    .next_btn {
      border-radius: 8px !important;
      background: var(--greys-primary-white, #fff) !important;
      box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12) !important;
      border: 1px solid #122d8e !important;

      color: #122d8e !important;
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 166px;
      height: 40px;
    }
    .ant-btn-default:disabled {
      border: 1px solid #c8d2f8 !important;
      color: #c8d2f8 !important;
    }
    .cancel_btn {
      border-radius: 8px;
      border: 0px solid var(--blues-blue-200, #c8d2f8);
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 166px;
      height: 40px;
      margin-left: 10px;
      box-shadow: none !important;
    }
  }

  .ant-upload-drag {
    border-radius: 8px;
    border: 1px dashed var(--primary-blue, #1638b1);
    background: #f3f5f9;
  }

  // ant input
  .ant-input {
    height: 40px;

    border-radius: 8px;
    border: 1px solid var(--greys-grey-500, #bcc3d0);
  }
  .ant-input::placeholder {
    color: var(--greys-grey-600, #a7aebb) !important;
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
  }

  // select feild
  .ant-select {
    .ant-select-selector {
      height: 40px;
      display: flex;
      align-items: center;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);

      .ant-select-selection-placeholder {
        color: var(--greys-grey-600, #a7aebb) !important;
        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        width: 100% !important;
      }
    }
    .ant-select-selection-search-input {
      height: 40px;
    }
  }
}

.choose_plan_container {
  // width: 80%;
  margin: auto;
  max-width: 980px;
  // height: calc(100vh - 100px);
  .para-desc {
    max-width: 512px;
    text-align: center;
  }
  .Discount_code {
    color: var(--blues-blue-800, #091647);

    /* Body text/Bold/B1 Bold */
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 12px; /* 66.667% */
  }
  .gap-48 {
    gap: 48px;
  }
  .mb-32 {
    margin-top: 32px;
  }
  .addUserPlanContainer {
    margin-top: 107px !important;
  }
  .reviewAndPayContainer {
    margin-top: 64px;
  }
  @media screen and (max-width: 1200px) {
    .gap-48 {
      gap: 24px;
    }
  }
  .subscription_card {
    border-radius: 8px;
    border: 1px solid var(--blues-blue-100, #e3e9fc);
    background: var(--greys-grey-100, #f8f9ff);
    padding: 20px 40px;
    .contact_icon {
      border-radius: 50%;
    }

    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      display: inline;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
    }

    .gap-10 {
      display: flex;
      gap: 10px;
    }

    h4 {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      margin: 0px;
      display: inline;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      display: inline;
    }
    .pointer {
      cursor: pointer;
    }

    .item_adder {
      max-width: 115px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: #fff;
      h5 {
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 66.667% */
        margin: 0px;
      }
    }
  }
  h2 {
    color: var(--blues-blue-900, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }

  .heading {
    color: var(--blues-blue-900, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mt-42 {
    margin-top: 42px;
  }
  .btnPadding {
    width: 122px;
  }
  // .inactiveBtn {
  .next_btn {
    border-radius: 8px !important;
    background: var(--greys-primary-white, #fff) !important;
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12) !important;
    border: 1px solid #122d8e !important;

    color: #122d8e !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 40px;
  }
  .ant-btn-default:disabled {
    border: 1px solid #c8d2f8 !important;
    color: #c8d2f8 !important;
  }

  //review and pay section
  .pay_modal_holder {
    .ant-input-affix-wrapper {
      border: 1px solid #d9d9d9 !important;
      &:hover {
        border: 1px solid #d9d9d9 !important;
      }
    }
    .review_pay_parent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    h2 {
      color: var(--blues-blue-900, #071135);
      text-align: center;
      font-family: Arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
    }
    p {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      line-height: normal;
    }
    .type_heading {
      color: #091647;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
    }

    .orderSummaryHeading {
      margin-top: 58px !important;
    }
    .black_color {
      color: black !important;
      width: 122px;
    }
    .checkout-amount {
      display: flex;
      justify-content: space-between;

      p {
        color: var(--blues-blue-800, #091647);
        font-family: Arial;
        margin: 0px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .apply_text {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }
    .ant-input-affix-wrapper {
      background: var(--greys-grey-100, #f8f9ff);
      margin-top: 20px;
    }
    .ant-input {
      border-radius: 8px;
      height: 100%;
      background: var(--greys-grey-100, #f8f9ff);
      height: 36px;

      color: var(--greys-grey-800, #858689);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-input::placeholder {
      background: var(--greys-grey-100, #f8f9ff);
      height: 100%;
    }
    .gap-10 {
      gap: 10px;
      display: flex;
    }
    .gap-23 {
      display: flex;
      gap: 23px;
    }
    .item {
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;
      border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
      margin-top: 18px;
    }
    .item_holder {
      padding-bottom: 20px;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }
    .mb-20 {
      margin-bottom: 20px;
    }
    .direction-col {
      flex-direction: column;
    }
    .mt-28 {
      margin-top: 28px !important;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .mt-7 {
      margin-top: 7px;
    }
    .reviewPayCard {
      border-radius: 12px;
      border: 1px solid #e4e8ed;
      padding: 14px 24px 24px 24px;
      .ant-input-affix-wrapper {
        margin-top: 0px !important;
      }
    }
    .discountMsg {
      margin-top: 10px !important;
      color: #31b948;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    // .item_adder {
    //   display: flex;
    //   align-items: center;
    //   gap: 8px;
    //   padding: 2px 6px;
    //   border-radius: 4px;
    //   h5 {
    //     color: var(--blues-blue-800, #091647);
    //     text-align: center;
    //     font-family: Arial;
    //     font-size: 18px;
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: 12px; /* 66.667% */
    //     margin: 0px;
    //   }
    //   .plus-minus-btn {
    //     padding: 5px;
    //     border-radius: 1px;
    //     background: var(--greys-grey-300, #e4e8ed);
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }
    // }
    .item_adder {
      max-width: 115px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: #fff;
      width: fit-content;
      margin-top: 4px;
      p {
        color: #071135;
        text-align: center;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 66.667% */
        margin: 0px !important;
        padding: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .onboarding_form_container {
    .para-desc {
      max-width: 338px;
    }
    .dp-none-sm {
      display: none !important;
    }
    .cancle-btn-sm {
      height: 40px;
      padding: 10px 20px;
      border-radius: 84px;
      border: 1px solid rgb(231, 230, 230);
      background: var(--greys-primary-white, #fff);
      box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .next-btn-sm {
      height: 40px;
      padding: 10px 20px;
      border-radius: 84px;
      border: 1px solid var(--blues-blue-200, #c8d2f8);
      background: var(--greys-primary-white, #fff);
      box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
      color: var(--blues-blue-200, #c8d2f8);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .onboarding-progress-container {
      overflow: auto;
      scrollbar-width: none; /* Hide the scrollbar  for firefox*/
      &::-webkit-scrollbar {
        display: none; /* Hide the scrollbar chrome safari */
      }
    }
  }
  .choose_plan_container {
    // width: 90%;
    margin: auto;
    h2 {
      font-size: 20px;
      font-style: normal;
    }
    .Discount_code {
      color: var(--blues-blue-800, #091647);

      /* Body text/Bold/B1 Bold */
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 12px; /* 66.667% */
    }
    .heading {
      font-size: 14px;
    }
    .subscription_card {
      padding: 20px;
      .card_margin_left {
        margin-left: 10%;
      }
      p {
        font-size: 16px;
      }

      h4 {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
    .pay_modal_holder {
      h2 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }

      .mt-14 {
        margin-top: 20px;
      }
    }
  }
}
