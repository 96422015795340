.advert_tracker_container {
  .align-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vertical-dot {
    background: #e3e9fc;
    padding: 8px;
    border-radius: 10%;
  }
  .advert-bold-text {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .advert_detail_box {
    padding: 24px 30px;
    border-radius: 15px;
    border: 1px solid #e4e8ed;
    background: #fff;
    .name {
      color: #272b39;
      /* Heading/H2 */
      font-family: Arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .detail_row {
      margin-top: 28px;
      .title {
        color: #272b39;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .detail {
        color: #272b39;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        word-break: break-word;
        text-transform: capitalize;
      }
      @media screen and (max-width: "1100px") {
        .detail {
          font-size: 13px;
        }
      }
      @media screen and (max-width: 767px) {
        .title {
          font-size: 12px;
        }
        .detail {
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .name {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
      }
    }
  }
  .tabsContainer {
    margin-top: 24px;
    padding-right: 1%;
  }
  .progressBox_container {
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    padding: 18px 24px;
    background: #fff;
    p {
      color: var(--blues-blue-900, #071135);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 7px !important;
      margin-top: 0px !important;
    }
    .progress-text {
      color: var(--blues-blue-900, #071135);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 143.75% */
    }
    .progress-text-bold {
      color: var(--blues-blue-900, #071135);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
    }
    @media screen and (max-width: "1100px") {
      p {
        font-size: 12px;
      }
      .progress-text {
        color: var(--blues-blue-900, #071135);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Arial;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 220% */
      }
      .progress-text-bold {
        color: var(--blues-blue-900, #071135);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Arial;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
      }
    }
    .progressBox {
      margin-top: 5px;
      border-radius: 100px;
      width: 100%;
      height: 9px;
    }
    .success_box {
      background: #8bd053;
    }
    .partial_box {
      background: #f9cf66;
    }
    .red_box {
      background: #f95c46;
    }
    @media screen and (max-width: 767px) {
      border-radius: 0px;
      border: 0px solid #e4e8ed;
      padding: 0px;
      background: #fff;
    }
  }
  .advert_table_box {
    margin-top: 28px;
    background: #fff;
    border-radius: 15px;
    .ant-table-title {
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
      background: var(--greys-grey-200, #f3f5f9);
    }
    .ant-table-wrapper .ant-table-selection-col {
      width: 50px !important;
    }

    thead {
      // background: #f3f5f9 !important;
      border-radius: 12px 12px 0px 0px !important;

      border-bottom: 1px solid #e4e8ed !important;
      td {
        background: transparent !important;
      }
      tr {
        border-radius: 12px 12px 0px 0px !important;
      }
    }
    th {
      background: #fff !important ;
      border-radius: 12px 12px 0px 0px !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    th::before {
      height: 0px !important;
    }
    td {
      background: #fff !important ;
      border-bottom: 1px solid #cecece !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
      label {
        padding-left: 8px;
      }
    }
    .delete {
      // color: #9ca9d8;
      text-align: center;

      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .candidate_report {
      display: flex;
      align-items: center !important;

      span {
        color: var(--blues-blue-600, #1638b1);

        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .deactivateLink {
      color: #a7aebb !important;
    }
    .activateLink {
      color: var(--blues-blue-600, #1638b1);
    }
    .searchBox {
      border-radius: 8px !important;
      //   border: 1px solid #e4e8ed !important;
      background: #fff !important;

      color: #1638b1 !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .ant-input:focus,
      .ant-input-focused {
        outline: none !important;
        border-color: initial !important; /* Reset the border color */
        box-shadow: none !important; /* Reset any box shadow */
      }

      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-affix-wrapper {
        border: 1px solid #e4e8ed !important;
        background: #fff !important;
        color: #9ca9d8 !important;
        height: 40px !important;
        input {
          background: #fff !important;
          color: #1638b1 !important;
          font-family: Arial;
          font-size: 16px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .ant-input-group-addon {
        display: none !important;
      }

      .ant-checkbox .ant-checkbox-inner {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox .ant-checkbox-inner:hover {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox-inner::after {
        background: #384b8e !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #384b8e !important;
        border-color: #384b8e !important;
      }
      @media screen and (max-width: 767px) {
        .ant-input-affix-wrapper {
          border: 0px solid #e4e8ed !important;
        }
        .ant-input-affix-wrapper:focus {
          outline: none !important;
        }
      }
    }
    .selectBox {
      .ant-select-selection-placeholder {
        color: var(--blues-blue-900, #071135) !important;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .ant-select-selector {
        min-height: 40px !important;
        display: flex;
        align-items: center;
        border-radius: 8px !important;
        border: 1px solid #e4e8ed !important;
        background: #fff !important;
        color: #071135 !important;
        font-family: Arial;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .ant-select-selection-item {
          border-radius: 8px !important;
          border: 1px solid var(--yellows-yellow-300, #f9cf66) !important;
          background: var(--yellows-yellow-200, #fae7b6) !important;
          display: flex !important;
          padding: 4px 8px !important;
          justify-content: center !important;
          align-items: center !important;
          gap: 7px !important;
        }
      }
      .ant-select-item {
        padding: 8px 10px !important;
      }

      .ant-select-item-option-content {
        color: #071135 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .leave_a_comment_row {
      .leave_comment {
        overflow: hidden;
        color: var(--greys-grey-600, #a7aebb);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .all_item_content-sm {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .advert_detail_box {
      padding: 10px !important;
    }
    .gbck {
      color: #091647;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .card-align-left {
      display: flex !important;
      justify-content: end !important;
    }
    .tabsContainer {
      margin-top: 24px;
      padding-right: 0%;
    }
    .tabsContainer-sm {
      display: flex !important;
      flex-direction: row !important;
      align-items: center;
      flex-wrap: nowrap !important;
      overflow-x: scroll !important;
      &::-webkit-scrollbar {
        // width: 0px !important;
        display: none;
      }
    }
    .tabs-child {
      text-wrap: nowrap !important;
    }
    .all-sign {
      margin-top: 15px;
      margin-bottom: -15px !important;
      width: 100% !important;
    }
    .all-sign-child {
      display: flex;
      justify-content: space-between;
    }
    .advert-bold-text {
      color: #000;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .advert_table_box td {
      background: #fff !important;
      border-bottom: 1px solid #cecece !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .table-heading-sm {
      background: #fff !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
  }
}
.multiselect_items_col {
  .verticalBox {
    width: 4px;
    height: 100%;
    background: #8bd053;
    border-radius: 8px;
  }
  .yellowVerticalBox {
    background: #f8c239 !important;
  }

  .redVerticalBox {
    background: #f95c46 !important;
  }
  .ant-select-item-option-state {
    display: none !important;
  }
}
.deactiveLink {
  color: #a7aebb !important;
}
.deactivatedLink {
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #a7aebb !important;
}
.activeLink {
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #1638b1 !important;
}
.abxcd {
  min-width: 170px !important;
}
.soft_requirement_table {
  th label {
    padding-left: 12px !important;
  }
}
