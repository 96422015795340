.notification_popup_row{
    z-index: 10;
    position: absolute;
    bottom: 100px;
    right: 50%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
border: 1px solid var(--states-red, #D03C28);
background: #FFF1F4;
display: flex;
width: 454px;
height: 57px;
padding: 8px 24px 17px 16px;
align-items: center;
gap: 16px;
.content{
    span{
        color: var(--states-red, #D03C28);

/* Body text/B3 */
font-family: Arial;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
}

}