.edit_user_profile_div {
  .name_title {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .personal_detail_main_col {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    .title_col {
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
      background: var(--greys-grey-200, #f3f5f9);
      display: flex;
      height: 66px;
      padding: 16px 20px 16px 24px;
      align-items: center;
      gap: 190px;
      span {
        color: var(--blues-blue-900, #071135);

        /* Body text/Bold/B1 Bold */
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 66.667% */
      }
    }
    .cards_Col{
      // margin-right: 10px ;
    }
    .first_form_col {
      padding: 16px 20px 16px 24px;
      .emailInput {
        border-radius: 8px !important;
        border: 1px solid #a7aebb !important;
        background: transparent !important;
        height: 42px !important;
        padding: 6px 12px !important;
        color: #f3f5f9 !important;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        // margin-right: ;
        line-height: normal;
      }
      .PhoneInput {
        background: red;
        // border: 2px solid red;
        border-right: none !important;
        border-radius: 8px 0px 0px 8px !important;
        & input {
          // background: transparent;
          color: black !important;
          border: none;
          pointer-events: none;
          cursor: not-allowed !important;
          &:focus {
            outline: none;
          }
        }
        & select {
          color: black;
        }
      }
    }
  }
  .permission_container {
    display: flex;
    margin-top: 24px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    // padding: 24px;
    .mb-24 {
      margin-bottom: 24px;
    }
    h2 {
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
      margin: 0px;
    }
    span {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
    }
    .title_col {
        border-radius: 12px 12px 0px 0px;
        border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
        background: var(--greys-grey-200, #f3f5f9);
        display: flex;
        height: 66px;
        padding: 16px 20px 16px 24px;
        align-items: center;
        gap: 190px;
        span {
          color: var(--blues-blue-900, #071135);
          padding-left: 24px;
          /* Body text/Bold/B1 Bold */
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px; /* 66.667% */
        }
      }
  }
  @media screen and (max-width:"767px") {
    .name_title{
      font-size: 22.4px !important;
    }
    .personal_detail_main_col{
      .title_col{
        background-color: white !important;
        border-bottom: none !important;
        padding: 16px 20px 10px 20px !important;
      }
      .cards_Col{
        margin-right: 0px !important;
        margin-top: 20px !important;
      }
      .first_form_col{
        padding: 0px 20px 16px 24px !important;
      }
    }
    .border_none{
      border: none !important;
    }
    .h-0{
      height: 0 !important;
    }
    
  }
}
