.archive_detail_container {
  .go-back {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: -10px;
  }
  .verticleLogo {
    width: 39px;
    height: 39px;
    border-radius: 4.404px;
    background: var(--blues-blue-100, #e3e9fc);
    margin-right: 2%;
  }
  .flex-archive {
    display: flex !important;
    align-items: end !important;
  }
  .archive-color-gary {
    font-family: Arial !important;
    color: var(--greys-grey-600, #a7aebb) !important;
    text-align: center;

    /* Body/P1/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .createBtnAdvert {
    cursor: not-allowed;
    height: 40px;
    padding: 10px 20px;

    border-radius: 8px;
    border: 1px solid var(--blues-blue-200, #c8d2f8);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    color: var(--blues-blue-200, #c8d2f8);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    & :hover {
      border-color: #c8d2f8;
      color: #c8d2f8;
    }
  }
  .countCardsMainDiv {
    .countCard {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      min-height: 138px;
      .heading_title {
        color: var(--Black, var(--blue-700, #272b39));
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 100% */
      }
      .amount {
        color: var(--Black, var(--blue-700, #272b39));
        font-family: Arial;
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 51.852% */
      }
      .total_accepted_col {
        border-radius: 8px;
        background: var(--yellows-yellow-100, #fffbe5);
      }
    }
    .countCardSecond {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      min-height: 138px;
      .second_section_inner_card {
        border-radius: 8px;
        background: var(--yellows-yellow-100, #fffbe5);
        min-height: 110px;
        width: 168px !important;
      }
    }
    @media screen and (max-width: 990px) {
      .lg-mb-20 {
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      .heading_title {
        font-size: 12px !important;
        line-height: normal; /* 100% */
      }
      .amount {
        font-size: 20px !important;
        line-height: normal;
      }
    }
  }
}
.campaignCard {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  min-height: 85px;
  padding: 12px 14px;
  // max-width: 252px;
  .title {
    color: #272b39;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    margin: 0;
    font-weight: 400;
    line-height: 12.262px; /* 100% */
  }
  .amount {
    color: #272b39;
    font-family: Arial;
    font-size: 33.107px;
    font-style: normal;
    font-weight: 700;
    margin-top: 20px !important;
    line-height: 17.167px; /* 51.852% */
    margin-bottom: 0px;
  }
  .detailBox {
    border-radius: 5px;
    background: #fffbe5;
    height: 100%;
    width: 100%;
    padding: 6px;
    .data {
      color: #071135;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 16.356px; /* 127.273% */
      width: 100%;
      margin: 0;
    }
    .detail {
      color: var(--blues-blue-900, #071135);
      text-align: center;
      width: 100%;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    .amount {
      font-size: 20px;
      margin-top: 20px !important;
    }
    .detail {
      font-size: 14px !important;
    }
  }
}
.campaignCardOne {
  background-image: url("../../Assets/campagin/cards/bg1.svg");
}
.campaignCardTwo {
  background-image: url("../../Assets/campagin/cards/bg2.svg");
}
.campaignCardThree {
  background-image: url("../../Assets/campagin/cards/bg3.svg");
}
.filterDropDown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  margin-left: 10px !important;
  min-width: 230px !important;
  max-width: 230px !important;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-radio-wrapper {
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-dropdown-menu-item {
    background-color: white !important;
  }
  .dropDown_radio_group {
    width: 100% !important;
    .filter_item_row {
      .fiter_button_dropdown_items {
        height: 30px !important;
        span {
          color: var(--Black, var(--blue-700, #272b39));

          /* Body text/B2 */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
.profileDropdown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  // margin-left: 10px !important;
  min-width: 230px !important;
  max-width: 230px !important;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    background: #fff !important;
    padding: 0 !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    &:hover {
      background: #fff !important;
    }
    li {
      padding: 0 !important;
    }
    .actionDropdown {
      .item {
        display: flex;
        display: flex;
        width: 131px;
        padding: 10px;
        align-items: center;
        gap: 6px;
        // border-radius: 8px 8px 0px 0px;
        border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
        background: var(--greys-primary-white, #fff);
        color: #071135 !important;
        cursor: pointer;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .logOutItem {
        color: #d03c28 !important;
        border-bottom: none !important;
      }
      .item:hover {
        background-color: #e3e9fc !important;
        // border-radius: 4px !important;
      }

      .ant-dropdown-menu-item {
        background-color: white !important;
        padding: 0 !important;
      }
    }
  }

  .ant-dropdown-menu-item {
    background-color: white !important;
  }
}
.successRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #8bd053 !important;
      border-radius: 2px;
    }
  }
}
.closeRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #f95c46 !important;
      border-radius: 2px;
    }
  }
}
.warningRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #f8c239 !important;
      border-radius: 2px;
    }
  }
}
.main_amount {
  color: var(--blues-blue-900, #071135);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
  @media screen and (max-width: 767px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 66.667% */
  }
}
.heading_title_inner {
  color: var(--blues-blue-900, #071135);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 767px) {
    color: var(--blues-blue-900, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
