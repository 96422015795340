.search-main-container {
  height: calc(100vh - 170px);
  .btn-invited {
    color: #c8d2f8;
    display: flex;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-200, #c8d2f8);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    font-weight: 700 !important;
    font-size: 16px !important;
    &:hover {
      color: #c8d2f8 !important;
      border: 1px solid var(--blues-blue-200, #c8d2f8) !important;
    }
  }

  .btn-invite {
    display: flex;
    height: 40px;
    padding: 10px 20px;
    color: #122d8e;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-700, #122d8e);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    font-weight: 700 !important;
    font-size: 16px !important;
    &:hover {
      color: #122d8e !important;
      border: 1px solid var(--blues-blue-700, #122d8e) !important;
    }
  }

  .cards-parent {
    height: calc(100vh - 250px);
    padding-right: 10px;
    overflow-y: scroll !important;
    .ant-spin-dot {
      margin-top: 30px !important;
    }
    &::-webkit-scrollbar {
      width: 8px;
      /* Adjust the width as needed */
    }

    &::-webkit-scrollbar-track {
      display: none;
      /* Hide the track */
    }

    &::-webkit-scrollbar-button {
      display: none;
      /* Hide the buttons (up and down arrows) */
    }

    &::-webkit-scrollbar-corner {
      display: none;
      /* Hide the scrollbar corner */
    }

    /* Style the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      padding-left: 10px !important;
      border-radius: 74px;
      background: var(--blues-blue-200, #c8d2f8);
    }
  }

  .main-container-part1 {
    padding: 10px 10px 0px 20px;
  }

  .search-input {
    margin: 20px 0px 10px 0px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
  }

  .body-text-b2 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* side bar || filter section*/
  .filter-section {
    padding: 10px 10px 0px 10px;
    height: calc(100vh - 50px);
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);

    .filter-body {
      height: calc(100vh - 180px);
      overflow: scroll;

      &::-webkit-scrollbar {
        width: 8px;
        /* Adjust the width as needed */
      }

      &::-webkit-scrollbar-track {
        display: none;
        /* Hide the track */
      }

      &::-webkit-scrollbar-button {
        display: none;
        /* Hide the buttons (up and down arrows) */
      }

      &::-webkit-scrollbar-corner {
        display: none;
        /* Hide the scrollbar corner */
      }

      /* Style the scrollbar thumb */
      &::-webkit-scrollbar-thumb {
        border-radius: 74px;
        background: var(--blues-blue-200, #c8d2f8);
      }
    }
  }

  /*  profile cards components design here */
  .profile-cards {
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 0px;
    flex-shrink: 0;
    flex-wrap: wrap;
    border-radius: 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);

    // .profile-icon {
    //   width: 50px;
    //   height: 50px;
    //   flex-shrink: 0;
    //   border-radius: 50%;
    //   border: 2px solid rgba(7, 17, 53, 0.5);
    // }

    .profile-para {
      color: #858689 !important;
      text-overflow: ellipsis;
      font-family: Arial;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .body-text-b3 {
      color: #071135 !important;
      font-family: Arial;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0px !important;
    }

    .body-text-bold-b4 {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.resetButton {
  display: flex;
  justifycontent: flex-end;
  margin-bottom: 2px;
  color: "#091647";
  img {
    height: 18px;
    width: 18px;
  }
}
.invite-modal {
  .ant-modal-title {
    color: #071135 !important;
    font-family: Arial;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  .ant-modal-footer {
    .ant-btn-primary {
      height: 40px !important;
      padding: 10px 20px !important;
      border-radius: 8px !important;
      background: var(--blues-blue-800, #091647) !important;
      color: var(--Primary-White, var(--greys-primary-white, #fff));
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .ant-btn-default {
      margin-right: 15px;
      height: 40px;
      padding: 10px 20px;
      border-radius: 8px;
      border: 1px solid var(--blues-blue-600, #1638b1);
      background: var(--greys-primary-white, #fff);
      color: var(--blues-blue-600, #1638b1);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:hover {
        @extend .ant-btn-default;
      }
    }
  }
}

.search-modal-main-container {
  .fade-text {
    color: var(--greys-grey-600, #a7aebb);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-modal-header {
    margin-bottom: 23px !important;
  }
  .searchModalTitle {
    color: #071135 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .searchModalInputBox {
      height: 40px !important;
      width: 100% !important;
      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);

      padding: 20px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none !important;
      color: #071135;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .candidateName {
    color: #071135 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .searchBy {
    color: #a7aebb !important;
    font-family: Arial;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .list-record {
    padding: 10px 0px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
    background: var(--Color-2, #fff);
  }
  .ant-tabs-tab:hover {
    color: #071135 !important;
  }
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .ant-tabs-ink-bar {
    background-color: #071135 !important;
    height: 4px !important;
    border-radius: 400px !important;
  }
  .ant-tabs-tab-btn {
    color: #a7aebb !important;
    text-align: center;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-shadow: none !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #071135 !important;
    text-align: center;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-shadow: none !important;
  }
  .viewAll {
    // color: #071135 !important;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .searchListRecordContainer {
    max-height: 370px;
    overflow-y: auto;
  }
}
.searchCheckBoxGroup {
  display: block;

  label {
    width: 100% !important;
    // display: block;
    margin-top: 20px !important;
    span {
      color: #272b39 !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.searchListItem {
  border-radius: 15px;
  background: #f8f9ff;
  padding: 20px;
  margin-bottom: 20px;
  .title {
    color: #071135;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .checkBoxContainer {
    max-height: 200px !important;
    overflow-y: auto;
    padding-left: 30px !important;
    .searchListItems {
      // margin-bottom: 30px !important;
      span {
        color: #272b39 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .searchListItems:not(:last-child) {
      margin-bottom: 20px !important;
    }
  }
}
.profileCardContainer {
  cursor: pointer;
  .profileCardWeb {
    @media screen and (max-width: "767px") {
      display: none;
    }
  }
  .profileCardMobile {
    display: flex;
    width: 100%;
    padding: 24px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 0px;
    flex-shrink: 0;
    flex-wrap: wrap;
    border-radius: 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
    margin-bottom: 20px;
    @media screen and (min-width: "768px") {
      display: none;
    }
    .item {
      color: #858689;
      font-family: Arial !important;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 4px !important;
    }
    .value {
      color: #071135 !important;
      font-family: Arial !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
  }
}
.globalSearchMobileDrawer {
  .filter-body {
    height: calc(100vh - 200px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
      /* Adjust the width as needed */
    }

    &::-webkit-scrollbar-track {
      display: none;
      /* Hide the track */
    }

    &::-webkit-scrollbar-button {
      display: none;
      /* Hide the buttons (up and down arrows) */
    }

    &::-webkit-scrollbar-corner {
      display: none;
      /* Hide the scrollbar corner */
    }

    /* Style the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      border-radius: 74px;
      background: var(--blues-blue-200, #c8d2f8);
    }
  }
}
.campaignListContainer {
  border-radius: 8px;
  height: 263px !important;
  max-height: 263px !important;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  padding: 20px 0px !important;
  box-shadow: 4px 0px 35px 0px #e7edf8;
}
.inviteCampaignDropDown {
  &:not(:last-child) {
    margin-bottom: 24px !important;
  }
  .name {
    color: #091647 !important;
    font-family: Arial;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .campaignContainer {
    padding: 5px 20px !important;
  }
  .activeCampaignContainer {
    background: #e3e9fc !important;
    padding: 5px 20px !important;
  }
  .advertContainer {
    margin-top: 14px;
    // margin-left: 30px;
    // margin-left: 20px !important;
  }
  .advertItem {
    margin-bottom: 10px;
    padding: 5px 20px 5px 40px !important;
  }
  .activeAdvertItem {
    background: var(--blues-blue-100, #e3e9fc);
    margin-bottom: 10px;
    padding: 5px 20px 5px 40px !important;
  }
}
