.login_main_container {
  align-items: center;
  overflow: hidden;
  width: 100%;
  background: #091647;
  height: 100vh;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px #091647 inset !important;
  }
  .loginImgContainer {
    height: 100vh !important;
    .loginImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .remember-me {
    color: #fff;
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #fff;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }
  .alertMsg {
    margin-top: -5px !important;
    margin-bottom: 8px;
    span {
      color: #d03c28;
      text-align: center;

      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .errorDescBox {
    border-radius: 15px;
    background: #f8f9ff;
    color: #071135;
    text-align: center;

    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    span {
      font-weight: 700;
    }
  }
  .strongText {
    color: #31b948;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .weakText {
    color: #d03c28;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-progress-status-normal .ant-progress-bg {
    background: #d03c28 !important;
  }
  .ant-progress-status-success .ant-progress-bg {
    background: #31b948 !important;
  }
  .formProgressBar {
    margin-bottom: 0px !important;
    .ant-progress-text {
      display: none !important;
    }
    .ant-progress-bg {
      // background: #d03c28 !important;
      height: 4px !important;
    }

    .ant-progress-outer {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 209px !important;
      height: 4px !important;
      .ant-progress-inner {
        background: #e4e8ed !important;
      }
    }
  }
  .login_container {
    padding: 38px 48px;
    width: 100%;
    height: 100%;
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #091647 inset !important;
      -webkit-text-fill-color: #f3f5f9 !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    @media screen and (max-width: 567px) {
      padding: 20px !important;
    }
  }
  .login_form_container {
    justify-content: center;
    display: flex;
    margin: auto !important;
    min-height: calc(100vh - 111px);
    align-items: center;
    max-width: 460px;
    .border-red {
      border: 1px solid #d03c28 !important;
    }
    .forgotHeader {
      width: 100%;
      margin-bottom: 40px;
      .imgBox {
        display: flex;
        justify-content: center;
      }
      .forgotHeading {
        color: #f3f5f9;
        margin: 16px 0 16px 0 !important;
        font-family: Arial;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
      }
      .desc {
        color: #f3f5f9;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0 !important;
      }
      @media screen and (max-width: 767px) {
        margin-top: 80px;
      }
    }
    .heading {
      color: #f3f5f9;
      margin: 0px !important;
      margin-bottom: 60px !important;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .notAMember {
      color: #f3f5f9;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px !important;
      margin-top: 100px !important;
      span {
        color: #fae7b6;
        cursor: pointer;
        font-weight: 700;
      }
    }
    .forgotPassword {
      color: var(--greys-primary-white, #fff);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }
    .termsCheckBox {
      .ant-checkbox {
        align-self: auto !important;
      }
    }
    form {
      max-width: 468px;
      .ant-form-item-row {
        display: block !important;
      }
      label::before {
        content: "" !important;
      }
      label::after {
        content: "" !important;
      }
      label {
        color: #f3f5f9 !important;

        font-family: Arial;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        line-height: 46.282px;
      }
    }
    align-items: center;
  }
  .submitBtn {
    border-radius: 8px !important;
    background: #f9cf66 !important;
    padding: 6px 12px !important;
    height: 40px !important;
    border: none !important;
    color: #071135 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    position: relative;
    .arrowBtn {
      position: absolute;
      right: 10px;
      top: 8px;
    }
    &:hover {
      outline: none !important;
      border: none !important;
    }
  }
  .mt-60 {
    margin-top: 60px !important;
  }
  .mt-42 {
    margin-top: 42px !important;
  }
  .emailInput {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 40px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .anticon svg {
    color: #f3f5f9 !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 40px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .ant-input {
      background: transparent !important;
      color: #f3f5f9 !important;
    }
  }
  input::placeholder {
    color: #a7aebb;

    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-form-item {
    margin-bottom: 18px !important;
  }
  .ant-checkbox-wrapper {
    line-height: normal !important;
  }
  .spamText {
    color: #f3f5f9;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 700 !important;
    }
  }
  .otpContainer {
    gap: 12px !important;
    justify-content: space-between;
    span {
      display: none !important;
    }
  }
  .otpInput {
    text-align: center;
    width: 100% !important;
    height: 60px;

    height: 60px;
    color: #fff !important;
    font-family: Arial;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none !important;
    border-bottom: 2.217px solid #f3f5f9 !important;
    border-radius: 0 !important;
    background: transparent !important;
  }
  .otpInput:focus-visible {
    /* outline: none !important; */
    outline: #636363 !important;
  }

  .resendText {
    cursor: pointer;
    color: #f9cf66;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
  }
  .cursor_wait {
    cursor: wait;
  }
}

/*==================styles for mobile screen========================*/
@media screen and (max-width: 767px) {
  .login_main_container {
    .logo_container {
      text-align: center;
      margin-top: 20px;
    }
    .remember-me {
      color: #fff;
      font-family: Arial !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: #fff;
        font-family: Arial !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
    }
    .company_logo {
      margin-top: 10%;
      width: 60%;
    }
    .login_form_container {
      min-height: calc(100vh - 400px);
      .heading {
        margin-top: 20% !important;
        margin-bottom: 20px !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
      }
      .forgotPassword {
        color: var(--Surface-Primary, #f3f5f9);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
      form {
        label {
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
      .notAMember {
        font-size: 16px;
        margin-top: 0px !important;
      }
      .forgotHeading {
        color: var(--Surface-Primary, #f3f5f9);
        /* Heading/H3 */
        font-family: Arial;
        font-size: 24px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .forgotHeader {
        margin-bottom: 20px;
      }
    }
    .submitBtn {
      border-radius: 8px !important;
    }

    .emailInput {
      font-size: 14px;
    }
    .ant-input-affix-wrapper {
      font-size: 14px;
    }
    .text-sm {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }
}
