.terms_of_use_container {
  .goback {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .dynamic_content_container {
    max-height: calc(100vh - 430px);
    padding-right: 8px;
    overflow: auto;
  }
  .apiData {
    padding-bottom: 20px !important;
  }
  .gray-text {
    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  p {
    color: #071135;

    /* Body text/B1 */
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .terms_heading {
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #071135 !important;
    margin-bottom: 5px !important;
  }
  .acceptanceText {
    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h1 {
    margin: 0px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h3 {
    color: var(--BG-Blue, #1b2444);

    /* Heading/H4 */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h4 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  h5 {
    margin: 0px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  ul {
    color: #071135;

    /* Body text/B1 */
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
  }

  span {
    color: #071135;

    /* Body text/B1 */
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-10 {
    margin-top: 10px;
  }

  .collapse-holder {
    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .ant-collapse {
      border: none;
      border-radius: 20px;
      background: #f3f5f9 !important;
    }

    .ant-collapse-item {
      border-bottom: 1px solid #bcc3d0;
      padding: 20px;
    }

    .ant-collapse-header {
      padding: 0px;
    }

    .ant-collapse-content {
      border-radius: 16px;
      background: #fff;
      border-top: none !important;
      border-bottom: none !important;
      margin: 9px 0px 20px 0px;
    }
  }
  @media screen and (max-width: 767px) {
    h3 {
      color: var(--BG-Blue, #1b2444);

      /* Heading/H4 */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    ul {
      color: var(--Primary-Grey, #575756);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.035px;
      opacity: 0.8;
    }
    p {
      color: #071135;

      /* Body text/B1 */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 156.25% */
      opacity: 0.8;
    }
  }
}
