.user_profile_main_div {
  .title_row {
    border-radius: 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    .title_col {
      padding: 24px !important;

      .title {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .edit_delete_buttons {
        display: flex !important;
        justify-content: flex-end;
        img{
          height: 42px !important;
          width: 42px !important;
        }
      }
    }
    .user_details_row {
      margin-top: 24px;
      p {
        margin: 0 !important;
      }
      .user_details_title {
        color: var(--blues-blue-900, #071135) !important;
        font-family: Arial !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
      .user_details_desc {
        color: var(--blues-blue-900, #071135) !important;
        margin-top: 10px !important;
        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
        word-wrap: break-word;
      }
    }
  }
  .table_header_div {
    background: #e4e8f6 !important;
    border-radius: 8px 8px 0 0 !important;
    .table_header_row {
      padding: 16px !important;
      background: #e4e8f6 !important;
      border-radius: 8px !important;
      .searchBox {
        border-radius: 8px !important;
        border: none !important;
        background: white !important;
        color: #9ca9d8 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 100%;
        // max-width: 399px;
        // min-width: 300px;
        .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
          border-radius: 8px !important;
        }
        .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
          border-radius: 8px !important;
        }
        .ant-input-affix-wrapper {
          background: white !important;
          color: #9ca9d8 !important;
          height: 40px !important;
          input {
            background: white !important;
            color: #9ca9d8 !important;
            font-family: Arial;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .ant-input-group-addon {
          display: none !important;
        }
      }
    }
  }
  .ant-table-title {
    padding: 0 !important;
    border: 8px !important;
  }
  .createBtn {
    border-radius: 8px;
    background: #f9cf66 !important;
    height: auto !important;
    padding: 10px 20px !important;
    width: 170px !important;
    color: #151d39 !important;
    border: none !important;
    text-align: center;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  //edit section main user (LOGIN USER)
  .edit_section_main_user {
    .name_title {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .personal_detail_main_col {
      border-radius: 12px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      .title_col {
        border-radius: 12px 12px 0px 0px;
        border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
        background: var(--greys-grey-200, #f3f5f9);
        display: flex;
        height: 66px;
        padding: 16px 20px 16px 24px;
        align-items: center;
        gap: 190px;
        span {
          color: var(--blues-blue-900, #071135);

          /* Body text/Bold/B1 Bold */
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px; /* 66.667% */
        }
      }
      .cards_Col {
        margin-right: 10px;
      }
      .first_form_col {
        padding: 16px 20px 16px 24px;
      }
    }
    @media screen and (max-width: "767px") {
      .name_title {
        font-size: 22.4px !important;
      }
      .personal_detail_main_col {
        .title_col {
          background-color: white !important;
          border-bottom: none !important;
          padding: 16px 20px 10px 20px !important;
        }
        .cards_Col {
          margin-right: 0px !important;
          margin-top: 20px !important;
        }
        .first_form_col {
          padding: 0px 20px 16px 24px !important;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .ant-table-container {
      overflow-x: scroll !important;
    }
    .title_row {
      .title_col {
        .title_name_column {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title {
            font-size: 20px !important;
          }
        }
        .edit_delete_buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }
      }
      .user_details_row {
        .user_details_title {
          font-size: 12px !important;
        }
        .user_details_desc {
          font-size: 14px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .contact_number_section {
          margin-top: 16px !important;
        }
      }
    }
    .createBtn-sm {
      width: 100% !important;
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--yellows-yellow-300, #f9cf66);
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.mr-5 {
  margin-right: 5px !important;
}
