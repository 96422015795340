.standard_plan_card_container {
  border-radius: 20px;
  border: 1px solid var(--greys-grey-300, #e4e8ed);
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  max-width: 344px;
  position: relative;
  .current_div {
    border-radius: 8px;
    border: 1px solid #93da9f;
    background: #caf3d1;
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 7px;
    width: 100%;
    height: 26px;
  }
  &:hover {
    border-radius: 20px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-100, #f8f9ff);
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
  }

  .none {
    display: none;
  }

  .active {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    text-align: center !important;
    border-radius: 12px;
    border: 1px solid #93da9f;
    background: #caf3d1;
    color: var(--blues-blue-800, #091647);
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

  // .active::before {
  //   content: "";
  //   width: 8px;
  //   height: 8px;
  //   background-color: #31b948;
  //   border-radius: 50%;
  //   display: flex;
  //   align-items: center !important;
  //   top: 12px;
  //   left: 6px;
  //   position: absolute;
  // }

  .dp_image {
    border-radius: 50%;
    border-radius: 50%;
    object-fit: cover; /* Add this line */
    width: 44px; /* Add this line to ensure the image takes the full width of its container */
    height: 44px; 
  }

  .gap-16 {
    gap: 16px;
    display: flex;
  }

  h4 {
    margin: 0px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline;
  }

  p {
    display: inline;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }

  .price_holder {
    margin-left: 60px;
  }

  .benefits_holder {
    margin-top: 28px;
    .gap-10 {
      align-items: center;
      display: flex;
      gap: 10px;
    }
    .mt-24 {
      margin-top: 24px;
    }
    .mt-12 {
      margin-top: 12px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .mt-16 {
      margin-top: 16px;
    }

    .mb-80 {
      margin-bottom: 80px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }

    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }
  }
  .buttons_main_div {
    // position: relative;
    // bottom: 6%;
    // left: 50%;
    // transform: translateX(-50%);
  }
}
.standard_plan_card_container_active {
  border-radius: 20px;
  border: 1px solid var(--greys-grey-300, #e4e8ed);
  background: var(--greys-grey-100, #e7e8ee);
  box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  max-width: 344px;
  position: relative;
  .current_div {
    border-radius: 8px;
    border: 1px solid #93da9f;
    background: #caf3d1;
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 7px;
    width: 100%;
    height: 26px;
  }

  .none {
    display: none;
  }

  .active {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    text-align: center !important;
    border-radius: 12px;
    border: 1px solid #93da9f;
    background: #caf3d1;
    color: var(--blues-blue-800, #091647);
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
  }

  // .active::before {
  //   content: "";
  //   width: 8px;
  //   height: 8px;
  //   background-color: #31b948;
  //   border-radius: 50%;
  //   display: flex;
  //   align-items: center !important;
  //   top: 12px;
  //   left: 6px;
  //   position: absolute;
  // }

  .dp_image {
    border-radius: 50%;
    border-radius: 50%;
    object-fit: cover; /* Add this line */
    width: 44px; /* Add this line to ensure the image takes the full width of its container */
    height: 44px; 
  }

  .gap-16 {
    gap: 16px;
    display: flex;
  }

  h4 {
    margin: 0px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: inline;
  }

  p {
    display: inline;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }

  .price_holder {
    margin-left: 60px;
  }

  .benefits_holder {
    margin-top: 28px;
    .gap-10 {
      align-items: center;
      display: flex;
      gap: 10px;
    }
    .mt-24 {
      margin-top: 24px;
    }
    .mt-12 {
      margin-top: 12px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .mt-16 {
      margin-top: 16px;
    }

    .mb-80 {
      margin-bottom: 80px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }

    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
    }
  }
  .buttons_main_div {
    // position: relative;
    // bottom: 6%;
    // left: 50%;
    // transform: translateX(-50%);
  }
}

.buy-user-modal {
  .ant-modal-content {
    padding: 40px;
  }
  .mt-67 {
    margin-top: 67px;
  }
  .mt-28 {
    margin-top: 28px;
  }
  .subscription_card {
    border-radius: 8px;
    border: 1px solid var(--blues-blue-100, #e3e9fc);
    background: var(--greys-grey-100, #f8f9ff);
    padding: 20px 40px;

    .contact_icon {
      border-radius: 50%;
    }

    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      display: inline;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
    }

    .gap-10 {
      display: flex;
      gap: 10px;
    }

    h4 {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      margin: 0px;
      display: inline;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      display: inline;
    }
    .pointer {
      cursor: pointer;
    }

    .item_adder {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: #fff;
      h5 {
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 66.667% */
        margin: 0px;
      }
    }
  }
}

.review_and_pay_modal {
  .ant-modal-content {
    padding: 20px;
    border-radius: 8px;
    background: var(--greys-grey-200, #f3f5f9);
  }

  .content-holder {
    padding: 0px 60px;
  }

  h2 {
    color: var(--blues-blue-900, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  p {
    margin: 0px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: normal;
  }
  .type_heading {
    color: var(--blues-blue-800, #091647);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 66.667% */
  }
  .black_color {
    color: black !important;
    width: 122px;
  }
  .checkout-amount {
    display: flex;
    justify-content: space-between;

    p {
      color: var(--blues-blue-800, #091647);
      font-family: Arial;
      margin: 0px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .apply_text {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .ant-input-affix-wrapper {
    background: var(--greys-grey-100, #f8f9ff);
    margin-top: 20px;
  }
  .ant-input {
    border-radius: 8px;
    height: 100%;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-100, #f8f9ff);
    height: 36px;

    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input::placeholder {
    background: var(--greys-grey-100, #f8f9ff);
    height: 100%;
  }
  .gap-10 {
    gap: 10px;
    display: flex;
  }
  .gap-23 {
    display: flex;
    gap: 23px;
  }
  .item {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
    margin-top: 10px;
  }
  .item_holder {
    padding-bottom: 20px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .direction-col {
    flex-direction: column;
  }
  .mt-7 {
    margin-top: 7px;
  }
  .item_adder {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 6px;
    border-radius: 4px;
    h5 {
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
      margin: 0px;
    }
    .plus-minus-btn {
      padding: 5px;
      border-radius: 1px;
      background: var(--greys-grey-300, #e4e8ed);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
