.addEditMessageMainDiv {
  .modal_main {
    border-radius: 15px !important;
    border: 1px solid var(--greys-grey-300, #e4e8ed) !important;
    background: var(--greys-grey-100, #f8f9ff) !important;
    
        .content_main_row {
          border-radius: 15px;
          border: 1px solid var(--greys-grey-300, #e4e8ed);
          background: var(--greys-primary-white, #fff);
          
        }
    
  }
}
