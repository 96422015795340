.buyuser-main-container {
  // margin-top: 88px !important;
  .ant-drawer-content-wrapper {
    width: 451px !important;
    border: 1px solid red;
  }
  .ant-drawer-header{
    border-bottom: none !important;
  }
  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse !important;
    svg{
      color: #091647 !important;
      font-size: 20px !important;
    }
  }
  .ant-drawer-title {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .buydrawer-text {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // padding: 40px 0 !important;
    padding-bottom: 24px !important;
    padding-top: 14px !important;
  }
  .order-summery {
    color: var(--blues-blue-800, #091647);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px !important;
    line-height: 12px; /* 66.667% */
  }
  .note-text {
    color: var(--greys-grey-700, #8f939b);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    & span {
      font-size: 14px;
      font-weight: 700;
    }
  }
  .total-amount {
    color: var(--blues-blue-800, #091647);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .price-ctrl-btn {
    padding: 2px;
    margin-top: 8px;
    display: flex;
    width: 70px;
    height: 21px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
  }
  .delte-icon {
    width: 24px;
    height: 24px;
  }
  .select-plan {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
  }
  .card-one {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    padding: 20px;
    .big-card-text {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .card-two {
    margin-top: 43px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
  }
  .pay-btn {
    display: flex;
    width: 162px;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    background: var(--yellows-yellow-300, #f9cf66);
    color: var(--blues-blue-800, #091647);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      color: var(--blues-blue-800, #091647) !important;
    }
  }
  .apply-text {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .discount-input {
    border-radius: 8px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-100, #f8f9ff);
    padding: 10px;
    & input {
      background: var(--greys-grey-100, #f8f9ff);
    }
    ::placeholder{
      color: var(--greys-grey-800, #858689);

/* Body text/B3 */
font-family: Arial;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
  }
  .discountMsg {
    margin-top: 10px !important;
    color: #31b948;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media screen and (max-width: 767px) {
   margin-bottom: 20px !important;
   .ant-drawer-header{
    padding-bottom: 0px !important;
   }
   .ant-drawer-body{
    padding-top: 10px !important;
   }
    .ant-drawer-title {
      font-size: 20px;
    }
    .total-amount {
      font-size: 18px;
    }
    .buydrawer-text{
      padding-top: 14px !important;
      margin: 0 !important;
    }
    .card-two{
      border: none !important;
    }
    .pay-btn{
      width: 100% !important;
    }
  }
}
