.top_Carousel_main_container {
  .campaign_carousel {
    border-radius: 5px !important;
    .slick-dots li {
      width: 17.524px !important;
    }

    .slick-dots li button {
      background: #fffbe5 !important;
      border-radius: 4.673px !important;
      opacity: 1 !important;
    }
    .slick-dots li.slick-active {
      width: 35.049px !important;
      button {
        background: #f9cf66 !important;
      }
    }
  }
  .header_carousel_item {
    border-radius: 5px !important;
    .imageContainer {
      position: relative;
      border-radius: 5px !important;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.2); /* Black see-through */
      color: #f1f1f1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      transition: 0.5s ease;
      font-size: 20px;
      padding: 20px;
      border-radius: 5px !important;
      .heading {
        color: #fff;
        font-family: Arial;
        font-size: 24px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .desc {
        color: #fff;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          cursor: pointer;
          color: #fcc009;
          font-size: 16px;
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 5px !important;
    }
  }
}
