.AlertStateMaindiv {
  position: absolute;
  bottom: 10%;
  z-index: 1000;
  left: 50%;
  transform: translate(-50%, -50%);
  .success_state_row {
    border-radius: 8px;
    border: 1px solid var(--states-green, #31b948);
    background: #f5fbf8;
    .outerBox_success {
      padding: 14px 16px !important;
      .messageText_success {
        color: #1d8c31;
        font-family: Arial;
        margin-left: 10px !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .error_state_row {
    border-radius: 8px;
    border: 1px solid var(--states-red, #d03c28);
    background: #fff1f4;
    .outerBox_error {
      padding: 14px 16px !important;
      .messageText_error {
        color: var(--states-red, #d03c28);
        margin-left: 10px !important;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
