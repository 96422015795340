.hardRequirementMainDiv {
  width: 100%;
  .hard_req_main_row {
    margin-top: 20px !important;

    .hard_requirement_title {
      color: var(--Text-Primary, #071135);
      text-align: center;

      /* Mobile responsive/Body text/B2 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .choose_requirement_main_col {
    border-radius: 8px;
    border: 0.5px solid var(--greys-grey-500, #bcc3d0);
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    height: 40px;
    padding: 10px;
    align-items: center;
    gap: 5px;
    margin-top: 10px !important;
    span {
      color: var(--greys-grey-600, #a7aebb);

      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .list_items_div {
    border-radius: 8px;
    background: #fff;

    box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12);
    // display: flex;
    width: 100% !important;
    margin-top: 10px;
    .inner_div {
      max-height: 250px !important;
      overflow-y: scroll !important;
    }
    .individual_item_col {
      display: flex;
      height: 32px;
      padding: 0px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      span {
        color: #071135;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .active {
      background: var(--blues-blue-100, #e3e9fc) !important;
    }
    .select-sm {
      display: flex;
      width: 208px;
      padding: 8px 15px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-top: 20px;
      border-radius: 8px;
      background: var(--blues-blue-800, #091647);
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      color: var(--greys-primary-white, #fff);
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .cancel-sm {
      display: flex;
      width: 196px;
      padding: 8px 15px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--blues-blue-600, #1638b1);
      background: var(--greys-primary-white, #fff);
      color: var(--blues-blue-600, #1638b1);
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 16px;
      margin-bottom: 23px;
    }
  }
  //CSS of selected cards
  .sub_category_main_col {
    border-radius: 8px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-100, #f8f9ff);
    padding: 12px;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    .selected_item_title {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .select_individual_item_col {
      border-radius: 8px;
      border: 0.5px solid var(--greys-grey-200, #f3f5f9);
      background: var(--Surface-Secondary, #fff);
      height: 40px;
      padding: 10px;
      gap: 5px;
      margin-top: 12px;
      span {
        color: var(--greys-grey-600, #a7aebb);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .select_dropdown {
      margin-top: 12px !important;
      .ant-select-selector {
        border: none !important;
        border-radius: 8px !important;
        border: 0.5px solid var(--greys-grey-200, #f3f5f9) !important;
        background: var(--Surface-Secondary, #fff) !important;
        // height: 40px !important;
        // padding: 10px !important;
        // gap: 5px !important;
      }
    }
  }
}
