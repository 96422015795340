.contact_us_container {
  border-radius: 20px;
  background: #f3f5f9;
  padding: 40px 60px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid var(--greys-grey-500, #bcc3d0);
    -webkit-text-fill-color: #071135;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .ant-select-arrow {
    svg {
      color: #122d8e !important;
      font-size: 15px;
    }
  }

  .textBoxInput {
    padding: 10px 10px 20px 10px;
  }
  .ant-form-item .ant-form-item-label > label {
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contactHeading {
    color: #071135;
    font-family: Arial;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 32px !important;
  }
  .contactImg {
    height: 100% !important;
    border-radius: 12px !important;
    background-image: url("../../Assets/contactCoverimage.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-height: 624px !important;
  }
  .contact_form {
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    background: #fff;
    padding: 24px;
  }

  // ant input
  .ant-input {
    height: 40px;
    // text-transform: capitalize;
    border-radius: 8px;
    border: 1px solid var(--greys-grey-500, #bcc3d0);
  }
  .ant-input::placeholder {
    color: var(--greys-grey-600, #a7aebb) !important;
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    display: flex !important;
    width: 100% !important;
    justify-content: center !important;
  }

  // select feild

  .ant-select {
    .ant-select-selector {
      height: 40px;
      display: flex;
      align-items: center;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
      outline: none !important;

      .ant-select-selection-placeholder {
        color: var(--greys-grey-600, #a7aebb) !important;
        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        width: 100% !important;
      }
    }
    .ant-select-selection-search-input {
      height: 40px;
    }
  }

  .customBtn {
    color: black !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .form_arrow {
    position: absolute;
    top: 11px;
    right: 10px;
  }
  .ant-form-item {
    margin: 0px !important;
  }
  @media screen and (max-width: 767px) {
    border-radius: 0px !important;
    border: none !important;
    background: #fff;
    padding: 0px;

    label {
      color: var(--Text-Primary, #071135);
      text-align: center;

      /* Body text/B2 */
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .goback {
      color: var(--blues-blue-900, #071135);

      /* Heading/H4 */
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: -5px !important;
    }
    .contact_form {
      border: 0px !important;
      padding-top: 0px;
    }
    .contactHeading {
      display: none;
    }
  }
}
.paddign-btm-contactus {
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}
.rc-virtual-list-scrollbar-thumb {
  background: #acbcf5 !important; /* color of the scroll thumb */
}
