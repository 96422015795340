.verifyotp_main_container {
  align-items: center;
  overflow: hidden;
  width: 100%;
  background: #091647;
  height: 100vh;
  .loginImgContainer {
    height: 100vh !important;
    .loginImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .otp_container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .forgotHeading {
    color: #f3f5f9;
    margin: 16px 0 16px 0 !important;
    font-family: Arial;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .desc {
    color: #f3f5f9;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0 !important;
  }
  .logo-img {
    width: 164.909px;
    height: 35px;
  }
  .verify_container {
    margin-top: 15%;
    // width: 35vw;
  }
  .main-heading {
    font-family: Arial;
    color: #f3f5f9;
    font-size: 36px;
    font-weight: 700;
    line-height: 41px;
    text-align: center;
  }
  .main-subheading {
    width: 365px;

    margin: auto;
    margin-bottom: 30px;
    color: var(--Surface-Primary, #f3f5f9);
    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
  }
  .alertMsg {
    margin: -10px 0px 10px 0px;
    span {
      color: #d03c28;
      text-align: center;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .progressbar_margin {
    margin: -10px 0px 5px 0px;
  }

  .errorDescBox {
    border-radius: 15px;
    background: #f8f9ff;
    color: #071135;
    text-align: center;

    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    span {
      font-weight: 700;
    }
  }
  .strongText {
    color: #31b948;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .weakText {
    color: #d03c28;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-progress-status-normal .ant-progress-bg {
    background: #d03c28 !important;
  }
  .ant-progress-status-success .ant-progress-bg {
    background: #31b948 !important;
  }
  .formProgressBar {
    margin-bottom: 0px !important;
    .ant-progress-text {
      display: none !important;
    }
    .ant-progress-bg {
      // background: #d03c28 !important;
      height: 4px !important;
    }

    .ant-progress-outer {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 209px !important;
      height: 4px !important;
      .ant-progress-inner {
        background: #e4e8ed !important;
      }
    }
  }
  .login_container {
    padding: 38px 48px;
    width: 100%;
    height: 100%;
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #091647 inset !important;
      -webkit-text-fill-color: #f3f5f9 !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    @media screen and (max-width: 567px) {
      padding: 20px !important;
    }
  }
  .login_form_container {
    justify-content: center;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 150px);
    .heading {
      color: #f3f5f9;
      margin: 0px !important;
      margin-bottom: 20px !important;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .notAMember {
      color: #f3f5f9;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px !important;
      margin-top: 20px !important;
      span {
        color: #fae7b6;
        cursor: pointer;
      }
    }
    .password_verification {
      background: #e4e8ed;
      border-radius: 15px;
      padding: 10px 20px 10px 20px;
      color: #071135;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      line-height: 16.2px;
      & span {
        font-weight: 600;
      }
    }
    .termsCheckBox {
      .ant-checkbox {
        align-self: auto !important;
      }
    }
    form {
      max-width: 468px;
      .ant-form-item-row {
        display: block !important;
      }
      label::before {
        content: "" !important;
      }
      label::after {
        content: "" !important;
      }
      label {
        color: #f3f5f9 !important;

        font-family: Arial;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        line-height: 46.282px;
      }
    }
    align-items: center;
    .custome_label {
      width: 100%;
      margin-bottom: -7px;
    }
  }
  .sucess_logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sucess_logo_img {
    width: 149px !important;
    height: 149px !important;
  }
  .span-text {
    text-decoration: underline;
    font-weight: 600;
    color: #f3f5f9;
  }
  .submitBtn {
    border-radius: 8px !important;
    background: #f9cf66 !important;
    padding: 6px 12px !important;
    height: 40px !important;
    border: none;
    color: #071135 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    position: relative;
    .arrowBtn {
      position: absolute;
      right: 10px;
      top: 8px;
    }
    &:hover {
      border: none;
    }
  }
  .mt-60 {
    margin-top: 60px !important;
  }
  .mt-42 {
    margin-top: 42px !important;
  }
  .emailInput {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 40px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .PhoneInput {
    border-right: none !important;
    border-radius: 8px 0px 0px 8px !important;
    & input {
      background: transparent;
      color: white;
      border: none;
      pointer-events: none;
      cursor: not-allowed !important;
      &:focus {
        outline: none;
      }
    }
    & select {
      color: black;
    }
  }

  .phoneNumberInput {
    border-radius: 0px 8px 8px 0px !important;
  }
  .PhoneInput --PhoneInputCountrySelectArrow-color {
    color: yellow;
  }
  .anticon svg {
    color: #f3f5f9 !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 40px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .ant-input {
      background: transparent !important;
      color: #f3f5f9 !important;
    }
  }
  input::placeholder {
    color: #a7aebb;

    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-form-item {
    margin-bottom: 18px !important;
  }
  .ant-checkbox-wrapper {
    line-height: normal !important;
  }
  .spamText {
    color: #f3f5f9;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 700 !important;
    }
  }
  .otpContainer {
    gap: 12px !important;
    justify-content: space-between;
    span {
      display: none !important;
    }
  }
  .otpInput {
    text-align: center;
    width: 100% !important;
    height: 60px;

    height: 60px;
    color: #fff !important;
    font-family: Arial;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none !important;
    border-bottom: 2.217px solid #f3f5f9 !important;
    border-radius: 0 !important;
    background: transparent !important;
  }
  .otpInput:focus-visible {
    /* outline: none !important; */
    outline: #636363 !important;
  }

  .resendText {
    cursor: pointer;
    color: #f9cf66;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
  }
  .cursor_wait {
    cursor: wait;
  }
}

/*==================styles for mobile screen========================*/
@media screen and (max-width: 767px) {
  .verifyotp_main_container {
    .logo_container {
      text-align: center;
      margin-top: 20px;
    }
    .company_logo {
      width: 60%;
    }
    .verify-logo-img {
      margin-top: 20%;
      text-align: center;
    }
    .logo-img {
      width: 242px;
      height: 52px;
    }
    .login_form_container {
      min-height: calc(100vh - 100px);
      .heading {
        margin-top: 30px !important;
        margin-bottom: 20px !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
      }
      .verify_container {
        margin-top: 15%;
        width: 80vw;
      }
      form {
        label {
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
      .notAMember {
        font-size: 16px;
        margin-top: 0px !important;
      }
      .custome_label {
        margin-bottom: 10px;
      }
    }
    .sucess_logo_img {
      width: 120px !important;
      height: 120px !important;
    }
    .submitBtn {
      border-radius: 8px !important;
    }

    .emailInput {
      font-size: 14px;
    }
    .ant-input-affix-wrapper {
      font-size: 14px;
    }
    .main-heading {
      color: var(--Surface-Primary, #f3f5f9);
      text-align: center;

      /* Heading/H3 */
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .main-subheading {
      width: 210px;
      color: #fff;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .forgotHeading {
      color: var(--Surface-Primary, #f3f5f9);
      text-align: center;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .desc {
      color: #f3f5f9;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin: 0 !important;
    }
  }
}
