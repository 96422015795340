.welcome_main_container {
  align-items: center;
  overflow: hidden;
  width: 100%;
  background: #091647;
  height: 100vh;
  .loginImgContainer {
    height: 100vh !important;
    .loginImg {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
  .footer {
    margin-top: -50px;
    width: 90%;
    margin: auto;
  }
  .login_container {
    padding: 38px 48px;
    width: 100%;
    height: 100%;
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #091647 inset !important;
      -webkit-text-fill-color: #f3f5f9 !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    @media screen and (max-width: 567px) {
      padding: 20px !important;
    }
  }
  .login_form_container {
    justify-content: center;
    display: flex;
    margin: auto !important;
    min-height: calc(100vh - 150px);
    align-items: center;
    max-width: 460px;

    .heading {
      color: #f3f5f9;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      text-align: center;
    }
    .notAMember {
      color: #f3f5f9;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 30px;
      span {
        color: #f9cf66;
        cursor: pointer;
      }
    }

    align-items: center;
  }
  .signinBtn {
    border-radius: 8px !important;
    border: none;
    background: #f9cf66 !important;
    padding: 6px 12px !important;
    height: 40px !important;
    color: #071135 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    position: relative;
    &:hover {
      border: none !important;
    }
  }
  .mt-60 {
    margin-top: 60px !important;
  }
  .mt-42 {
    margin-top: 42px !important;
  }

  .body_text {
    font-size: 18px !important;
    font-family: Arial;
    font-weight: 400;
    line-height: 20.7px;
    text-align: center;
    color: #f3f5f9;
  }
}

@media screen and (max-width: 768px) {
  .welcome_main_container {
    .logo_image {
      width: 65%;
      max-width: 250px;
    }
    .mt-21 {
      margin-top: 21px;
    }
    .company_logo_container {
      text-align: center;
      margin-top: 50px;
      width: 100%;
    }
    .footer {
      margin-top: -20px;
      width: 95%;
      margin: auto;
    }
    .login_form_container {
      justify-content: center;
      display: flex;
      margin: auto !important;
      min-height: calc(100vh - 200px);
      align-items: center;
      max-width: 460px;

      .heading {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
      }
      .notAMember {
        margin-top: 10px;
        font-size: 16px;
        line-height: 18px;
      }
    }
    .signinBtn {
      border-radius: 8px !important;
    }
    .body_text {
      font-size: 16px !important;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
