.status_main_modal {
  .ant-modal-title {
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .candidate_name {
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  .main_Content_row {
    .heading {
      color: var(--greys-grey-800, #858689) !important;
      font-family: Arial !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    .description {
      color: var(--Text-Primary, #071135) !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }
  .ant-modal-footer {
    display: flex !important;
    justify-content: flex-end !important;
    .ant-btn-primary {
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(--blues-blue-900, #071135);
      &:hover {
        background: var(--blues-blue-900, #071135) !important; /* Same color on hover */
      }

    }
    .ant-btn-default {
      border-radius: 8px;
      border: 1px solid var(--blues-blue-600, #1638b1);
      background: var(--greys-primary-white, #fff);
      display: flex;
      width: 95px;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
}
