.advert_detail_container {
  .advert_detail_box {
    padding: 24px 30px;
    border-radius: 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    .gap-12 {
      display: flex;
      gap: 12px;
    }
    .name {
      color: #272b39;
      /* Heading/H2 */
      font-family: Arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .detail_row {
      margin-top: 28px;
      .title {
        color: var(--greys-grey-800, #858689);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .detail {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        word-break: break-word;
        text-transform: capitalize;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
      border-radius: 20px;
      .name {
        font-size: 20px;
        line-height: normal;
      }
      .detail_row {
        margin-top: 20px;
        .title {
          font-size: 12px;
        }
        .detail {
          font-size: 14px;
        }
        .align-text-end-sm {
          text-align: end;
        }
      }
    }
  }
  /************styles for table**************/
  .advert_summary_box {
    margin-top: 33px;
    .btn_sm {
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--yellows-yellow-300, #f9cf66);
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    h3 {
      color: #272b39;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .advert_table_box {
      border-bottom: 1px solid #cecece !important;
      background: #fff !important;
      border-radius: 15px !important;
      border: 1px solid #e4e8ed !important;
      .selectedRow_active {
        color: var(--states-red, #d03c28);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .selectedRow_active_status {
        font-family: Arial;
        color: var(--blues-blue-600, #1638b1);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .candidate_report_col {
        span {
          color: var(--blues-blue-600, #1638b1);
          text-align: center;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .search_traker_button_col {
        display: flex;
        justify-content: flex-end;
      }
      margin-top: 28px;
      //   border-bottom: 1px solid #cecece;
      background: #fff;
      border-radius: 15px;
      .ant-table-title {
        border-bottom: 1px solid #cecece !important;
        padding: 16px 16px !important;
      }
      th {
        background: #fff !important;
        border-bottom: 1px solid #cecece !important;
        color: #1b2444 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
      }
      th::before {
        height: 0px !important;
      }
      td {
        background: #fff !important ;
        border-bottom: 1px solid #cecece !important;
        color: #1b2444 !important;
        font-family: Arial;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
      }
      .delete {
        color: #9ca9d8;
        text-align: center;

        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .searchBox {
        border-radius: 8px !important;
        border: 1px solid #e4e8ed !important;
        background: #f3f5f9 !important;
        color: #9ca9d8 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // max-width: 399px;
        // min-width: 300px;
        .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
          border-radius: 8px !important;
        }
        .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
          border-radius: 8px !important;
        }
        .ant-input-affix-wrapper {
          border: 1px solid #e4e8ed !important;
          background: #f3f5f9 !important;
          color: #9ca9d8 !important;
          height: 40px !important;
          input {
            background: #f3f5f9 !important;
            color: #1638b1 !important;
            font-family: Arial;
            font-size: 16px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .ant-input-group-addon {
          display: none !important;
        }
      }
      .btn {
        border-radius: 8px;
        background: #f9cf66 !important;
        height: auto !important;
        padding: 10px 20px !important;
        width: 100%;
        // width: 156px !important; //creating problem in responsive
        color: #151d39 !important;
        border: none !important;
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .ant-checkbox .ant-checkbox-inner {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox .ant-checkbox-inner:hover {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox-inner::after {
        background: #384b8e !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #384b8e !important;
        border-color: #384b8e !important;
      }

      .ant-switch-inner {
        background: #9ca9d8 !important;
      }
      .ant-switch-checked .ant-switch-inner {
        background: #384b8e !important;
      }
      @media screen and (max-width: 767px) {
        th {
          font-size: 14px !important;
        }

        td {
          font-size: 12px !important;
        }
        .table-font-14 {
          font-size: 12px !important;
          line-height: normal;
        }
      }
    }
    .dp-none-md {
      display: none;
    }
    @media screen and (max-width: 767px) {
      .dp-none-sm {
        display: none !important;
      }
      .sm-w100 {
        width: 100% !important;
      }
      .sm-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .dp-none-md {
        display: block;
      }
      .advert-vertical-dots {
        background: #e3e9fc;
        padding: 10px;
        border-radius: 5px;
        margin-left: 40%;
      }
    }
  }
  .advert-go-back {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.action-dropdown {
  .item {
    display: flex;
    display: flex;
    width: 131px;
    padding: 8px 12px;
    align-items: center;
    gap: 6px;
    border-radius: 8px 8px 0px 0px;
    background: var(--greys-primary-white, #fff);
  }
  .item:hover {
    background-color: #e3e9fc !important;
    border-radius: 4px !important;
  }
  span {
    color: var(--text-primary, #071135);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-dropdown-menu-item {
    background-color: white !important;
    padding: 0 !important;
  }
}

.advert_summary_container {
  .ml-24 {
    margin-left: 24px;
  }
  .other_data_holder {
    padding: 18px 20px;
    border-radius: 10px;
    background: var(--yellows-yellow-200, #fae7b6);
  }
  .card-holder {
    padding: 14px 0px;
    border-radius: 8px;
    background: var(--yellows-yellow-200, #fae7b6);
    display: flex;
    justify-content: space-between;
    min-height: 138px;
    .gap-10 {
      display: flex;
      gap: 10px;
    }
    .count {
      color: var(--black, #272b39);
      font-family: Arial;
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 51.852% */
      position: absolute;
      bottom: 20px;
    }
  }
  p {
    margin: 0px;
    color: var(--black, #272b39);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 100% */
  }
  h3 {
    margin: 0px;
    margin: 24px 0px 18px -24px;
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: "1100px") {
    .other_data_holder {
      padding: 10px 10px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      background: var(--yellows-yellow-200, #fae7b6);
    }
    h3 {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
    }
    p {
      margin: 0px;
      color: var(--black, #272b39);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
    }
    .card-holder {
      display: flex;
      justify-content: space-between;
      .count {
        color: var(--black, #272b39);
        font-family: Arial;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 51.852% */
        position: absolute;
        bottom: 20px;
      }
    }
  }
}

.advert_summary_card_container {
  border-radius: 8px;
  background: #fffbe5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 8px 15px;
  .mt-8 {
    margin-top: 8px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .summary-card-text {
    display: flex;
    text-align: center;
  }
}
@media screen and (max-width: "1100px") {
  .advert_summary_card_container {
    height: 120px;
  }
}
.approvedStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    padding: 6px 8px;
    border-radius: 4px;
    border: 1px solid #93da9f;
    background: #caf3d1;
    font-weight: 700 !important;
  }
}
.rejectStyleClass {
  font-weight: 700 !important;
}
