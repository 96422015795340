.hardRequirementSection_phone {
  margin-top: 20px;
  .heading {
    color: var(--Text-Primary, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .choose_requirement_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 0.5px solid var(--greys-grey-500, #bcc3d0);
    width: 100%;
    height: 40px;
    padding: 10px;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    .placeholder_text {
      color: var(--greys-grey-600, #a7aebb);
      text-align: center;

      /* Mobile responsive/Body text/B2 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .pickList_main_div {
    height: 350px;
    overflow-y: auto !important;
    border-radius: 8px;
    background: #fff;
    box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12);
    margin-top: 4px;
    .picklist_row {
      .picklist_item {
        display: flex;
        height: 32px;
        padding: 0px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        // background: var(--blues-blue-100, #e3e9fc);
        span {
          color: var(--blues-blue-800, #091647);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .active {
        background: var(--blues-blue-100, #e3e9fc) !important;
      }
    }
    .select_cancel_button_section {
      padding: 25px 0;
      .select_btn {
        border-radius: 8px;
        background: var(--blues-blue-800, #091647);
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        display: flex;
        width: 208px;
        padding: 8px 15px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        span {
          color: var(--greys-primary-white, #fff);
          text-align: center;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .cancel_button {
        border-radius: 8px;
        border: 1px solid var(--blues-blue-600, #1638b1);
        background: var(--greys-primary-white, #fff);
        display: flex;
        width: 196px;
        padding: 8px 15px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        span {
          color: var(--blues-blue-600, #1638b1);
          text-align: center;

          /* Body text/B3 */
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .selected_pickList_main_div {
    .main_box {
      border-radius: 8px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: var(--greys-grey-100, #f8f9ff);
      padding: 12px;
      margin: 12px 0;
      .title_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          color: var(--Text-Primary, #071135);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .pickList_select_col {
        border-radius: 8px;
        border: 0.5px solid var(--greys-grey-200, #f3f5f9);
        background: var(--Surface-Secondary, #fff);
        display: flex;
        width: 100%;
        height: 40px;
        padding: 10px;
        align-items: center;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        span {
          color: var(--greys-grey-600, #a7aebb);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .tagList_main_div {
        max-height: 200px !important;
        overflow-y: auto;
        width: 100%;
        margin-top: 4px;
        .tag_col {
          display: flex;
          height: 32px;
          padding: 0px 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          background-color: white !important;
        }
        .active {
          background: var(--blues-blue-100, #e3e9fc) !important;
        }
        .disabled {
          background: var(--greys-grey-300, #e4e8ed) !important;
          color: #a7aebb !important;
        }
      }
    }
  }
  .post_advert_section {
    display: flex;
    align-items: center;
    justify-content: center;
    .post_btn {
      border-radius: 8px;
      background: var(--yellows-yellow-300, #f9cf66);
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 5px;
      align-self: stretch;
      span {
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .can_btn {
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 5px;
      align-self: stretch;
      border: none !important;
      span {
        color: var(--blues-blue-700, #122d8e);
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .post_advert_section_abs {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 20px;
    .post_btn {
      border-radius: 8px;
      background: var(--yellows-yellow-300, #f9cf66);
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 5px;
      align-self: stretch;
      span {
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .can_btn {
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 5px;
      align-self: stretch;
      border: none !important;
      span {
        color: var(--blues-blue-700, #122d8e);
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
