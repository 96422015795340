.loading_state_modal {
    .ant-modal-content{
        height: 318px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }

}
