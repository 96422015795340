.addCampaignModal_main_row{
    
    
}
.addCampaignModal{
    .selected_item_div{
        border-radius: 8px;
        border: 1px solid var(--yellows-yellow-300, #f9cf66);
        background: var(--yellows-yellow-200, #fae7b6);
        display: flex;
        padding: 4px 8px;
        align-items: center;
        margin-right: 8px;
    }
}