.create_new_password_modal {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #071135 !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  svg {
    color: #071135 !important;
  }
  .ant-form-item-required {
    color: #071135;
    text-align: center;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .content_main_row {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
    .title {
      color: #262f4f;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      justify-content: center;
    }
    .title_desc {
      color: #071135;
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .password_form_item {
    margin-top: 20px !important;
    .ant-form-item-required {
      color: #071135;
      text-align: center;

      /* Body text/B1 */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .alertMsg {
    margin-top: -18px;
    margin-bottom: 20px;
    span {
      color: #d03c28;
      text-align: center;

      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .errorDescBox {
    border-radius: 15px;
    background: var(--greys-grey-300, #e4e8ed);
    color: #071135;
    text-align: center;
    margin-bottom: 40px !important;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    span {
      font-weight: 700;
    }
  }
  .cp_modal_btn {
    width: 100% !important;
    border-radius: 8px;
    background: var(--Button-Primary, #f9cf66);
    display: flex;
    width: 460px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    color: var(--Text-Primary, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      background: var(--Button-Primary, #f9cf66) !important;
      color: var(--Text-Primary, #071135) !important;
      border: none !important;
    }
    .arrowBtn {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
  .otpInput {
    text-align: center;
    width: 100% !important;
    height: 60px;
    margin: 0 10px !important;
    height: 60px;
    color: #262f4f !important;
    font-family: Arial;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none !important;
    border-bottom: 3px solid #f3f5f9 !important;
    border-radius: 0 !important;
    background: transparent !important;
    span {
      display: none !important;
    }
  }
  .otpInput:focus-visible {
    /* outline: none !important; */
    outline: #636363 !important;
  }

  .resendText {
    cursor: pointer;
    color: #262f4f;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
  }
  .cursor_wait {
    cursor: wait;
  }
  .submitBtn {
    border-radius: 8px !important;
    background: #f9cf66 !important;
    padding: 6px 12px !important;
    height: 40px !important;

    color: #071135 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    position: relative;
    .arrowBtn {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
  .spamText {
    color: #262f4f;
    text-align: center;

    /* Body text/B2 */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 700 !important;
    }
  }
  .strongText {
    color: #31b948;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .weakText {
    color: #d03c28;

    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .formProgressBar {
    margin-bottom: 0px !important;
    .ant-progress-text {
      display: none !important;
    }
    .ant-progress-bg {
      // background: #d03c28 !important;
      height: 4px !important;
    }

    .ant-progress-outer {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 209px !important;
      height: 4px !important;
      .ant-progress-inner {
        background: #e4e8ed !important;
      }
    }
  }
  .formProgressBar_red {
    margin-bottom: 0px !important;
    .ant-progress-text {
      display: none !important;
    }
    .ant-progress-bg {
      background: #d03c28 !important;
      height: 4px !important;
    }

    .ant-progress-outer {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 209px !important;
      height: 4px !important;
      .ant-progress-inner {
        background: #e4e8ed !important;
      }
    }
  }
}
