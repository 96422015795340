.upgradeMembershipModal{
    .ant-modal-title{
        color: var(--blues-blue-900, #071135) !important;
font-family: Arial !important;
font-size: 24px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: normal !important;
    }
    .modal_content{
        color: var(--Text-Primary, #071135) !important;

/* Body text/B1 */
font-family: Arial !important;
font-size: 18px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
    }
}