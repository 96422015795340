// for samll notification screen
.notification-container {
  .Notifications-header {
    border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
    background: var(--Color-2, #fff);
    padding: 14px 20px;
  }
  .notification-heading {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .viewall-text {
    cursor: pointer;
    color: var(--blues-blue-600, #1638b1);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .notification-cards-container {
    max-height: 300px;
    overflow-y: scroll;
    // margin-top: 20px;
  }
  .card-text {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    & span {
      font-weight: 700;
    }
  }
  .card-time-text {
    margin-top: -7px !important;
    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .notification-card-outline {
    border-bottom: 0.5px solid var(--greys-grey-300, #aaaaaa);
    background: var(--Color-2, #fff);
    padding: 12px 20px;
  }
  .notification-card-outline-read{
    border-bottom: 0.5px solid #aaaaaa;
    background: var(--greys-grey-300, #eff1f5);
    padding: 12px 20px;
  }
  .notification-footer {
    padding: 12px 10px 12px 20px;
    background: var(--Color-2, #fff);
    box-shadow: -1px -2px 8px 0px rgba(0, 0, 0, 0.12);
    & span {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.noRecentNotification {
  margin-top: 16px;
  color: var(--Text-Primary, #071135);
  font-family: Arial;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
//for big notification screen
.drawer-container {
  overflow: hidden !important;
  .ant-drawer-header-title {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .Notifications-header {
    border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
    background: var(--Color-2, #fff);
    padding: 14px 20px;
  }
  .notification-heading {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .notification-cards-container {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // margin-top: 20px;
  }
  .card-text {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0px !important;
    line-height: normal;
    & span {
      font-weight: 700;
    }
  }
  .card-time-text {
    margin-top: -7px !important;
    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .notification-card-outline {
    border-bottom: 0.5px solid var(--greys-grey-300, #aaaaaa);
    background: var(--Color-2, #fff);
    padding: 12px 20px;
  }
  .notification-card-outline-read{
    border-bottom: 0.5px solid #aaaaaa;
    background: var(--greys-grey-300, #eff1f5);
    padding: 12px 20px;
  }
  .drawer-header {
    padding: 12px 10px 12px 20px;
    background: var(--Color-2, #fff);
    box-shadow: -1px -2px 8px 0px rgba(0, 0, 0, 0.12);
    & span {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .day-text {
    padding: 12px 20px 0px 20px;
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .filter-icon {
    margin-left: 15px;
    cursor: pointer;
  }
}
