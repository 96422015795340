.document_box_container {
  border-radius: 15px;
  border: 2px solid #e4e8ed;
  background: #f8f9ff;
  padding: 12px;
  .title {
    color: #071135;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 3px 0 !important;
  }
  .size {
    color: #858689;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 3px 0 !important;
  }
  .date {
    color: #858689;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
  }
}
.noDocModal{
  .ant-modal-title{
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .desc{
    color: var(--Text-Primary, #071135) !important;
    font-family: Arial !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}