.select_campaigns_main_modal {
  //selected Item
  .ant-modal-title{
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  svg{
    color: #071135 !important;
    // margin-top: 10px !important;
  }
  .selected_item_div {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    display: flex;
    padding: 4px 8px;
    align-items: center;
    margin-right: 8px;
  }
  //content Section
  .selectCampaign_main_row {
    max-height: 256px !important;
    overflow-y: auto !important;
    border-radius: 8px;
    background: var(--greys-primary-white, #fff);
    box-shadow: 4px 0px 35px 0px #e7edf8;
    margin-top: 8px !important;
    .individual_main_col {
      display: flex;
      align-items: center !important;
      height: 34px;
      cursor: pointer;
      padding: 10px 20px;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;
    }
    .active {
      background: var(--blues-blue-100, #e3e9fc) !important;
    }
  }
}
