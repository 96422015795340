.subscription_management_container {
  .gap-48 {
    display: flex;
    gap: 48px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }
  h1 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  p {
    margin: 0px;
    color: var(--text-primary, #071135);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .gap-38 {
    display: flex;
    gap: 38px;
  }
  .subs-heading {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 !important;
  }
  .subs-sub-heading {
    color: var(--Text-Primary, #071135) !important;
    font-family: Arial !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  .user-count {
    color: var(--blues-blue-700, #122d8e) !important;
    font-family: Arial !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 12px !important;
  }
  @media screen and (max-width: 767px) {
    .sm-buy-btn-parent {
      display: flex;
      justify-content: end;
    }
    .subs-heading {
      font-size: 16px;
    }
    .subs-sub-heading {
      font-size: 12px !important;
    }
    .user-count {
      font-size: 14px !important;
    }
    .buy-userbtn-parent {
      display: none !important;
    }
    .subs-header-main {
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      span{
        font-size: 16px !important;
      }
    }
    .gap-38 {
      justify-content: space-between !important;
    }
  }
  /**************************/
  @media screen and (max-width: 991px) {
    .sm-mt-10 {
      margin-top: 10px;
    }
    
  }

  .subs-switch {
    // width: 40px;
    // height: 20px;
    margin-left: 14px;
  }
  .buy-userbtn-parent {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .buyuserBtn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 40px;
    border: 1px solid var(--blues-blue-700, #122d8e);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    padding: 10px 20px 10px 20px !important;
    & span {
      color: var(--blues-blue-700, #122d8e) !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal;
    }
    &:hover {
      border: 1px solid var(--blues-blue-700, #122d8e) !important;
    }
  }
  .buyuserBtn1 {
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 30px;
    border: 1px solid var(--blues-blue-700, #122d8e);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    padding: 10px 20px 10px 20px !important;
    margin-bottom: 5px;
    & span {
      color: var(--blues-blue-700, #122d8e) !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal;
    }
    &:hover {
      border: 1px solid var(--blues-blue-700, #122d8e) !important;
    }
  }
  .flex-text-center {
    display: flex;
    align-items: center;
  }
  .cart-icon {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  /**************************/
  .total_users_details {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 57px;

    .mb-18 {
      margin-bottom: 18px;
    }

    .space-btw {
      display: flex;
      justify-content: space-between;
    }

    .justify-end {
      display: flex;
      justify-content: flex-end;
    }
    .align-end {
      display: flex;
      align-items: flex-end;
    }

    p {
      margin: 0px;
      color: var(--blues-blue-700, #122d8e);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
    }

    h4 {
      color: var(--black, #272b39);
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
      margin-bottom: 12px;
    }

    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    @media screen and (max-width: 1230px) {
      span {
        font-size: 14px;
      }
      h4 {
        font-size: 16px;
      }
    }
  }
  .payment_history_table {
    // width: 100% !important;
    width: calc(100vw - 48px);
    margin-top: 20px;
    th {
      background-color: #f3f5f9 !important;
      color: var(--greys-grey-800, #858689) !important;
      
      font-family: Arial !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 700;
      line-height: normal !important;
    }
    @media screen and (max-width:"767px") {
       .ant-table-cell-fix-left-last{
        width: 233px !important;
      }
    }
  }
  @media screen and (max-width:"767px") {
    .buyuserBtn{
      height: 32px !important;
    }
  }
}
.subscription_user_col{
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  @media screen and (max-width:"767px") {
    gap: 5px !important;
  }
}