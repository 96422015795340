.add_user_modal_container {
  .ant-modal-close-x {
    font-size: 20px !important;
    svg {
      color: #071135 !important;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;

    .ant-btn-primary {
      border-radius: 8px;
      background: var(--blues-blue-800, #091647);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 10px 20px;
    }
    .ant-btn-default {
      border-radius: 8px;
      border: 1px solid var(--blues-blue-600, #1638b1);
      background: var(--greys-primary-white, #fff);
      width: 95px;
      height: 40px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
  .ant-modal-title {
    color: var(--Text-Primary, #071135) !important;
    font-family: Arial !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .choose_user_title_col {
    border-bottom: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    display: flex;
    padding-top: 12px ;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    p {
      margin: 0 !important;
    }
    .input_box_users{
      box-shadow: 4px 0px 35px 0px #E7EDF8;
      display: flex;
padding: 12px 8px;
align-items: center;
gap: 10px;
align-self: stretch;
border-top: none;
border-right: none;
border-left: none;
border-bottom: 1px solid var(--greys-grey-300, #E4E8ED);
background: #FFF;
color: var(--greys-grey-800, #858689);

/* Body text/B2 */
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
&:focus-visible{
  box-shadow: none !important;
}
    }
    .choose_user_title {
      color: var(--greys-grey-800, #858689);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .individual_item_row_active {
    display: flex;
    padding: 8px;
    background: var(--blues-blue-100, #e3e9fc);
    align-items: center;
    // gap: 10px;
    // margin-bottom: 6px;
    align-self: stretch;
    .align_check_left {
      display: flex;
      justify-content: flex-end;
    }
    span {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .user_type_col {
      border-radius: 8px;
      background: #fff;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      span {
        text-align: center;
      }
    }
  }
  .individual_item_row_inactive {
    display: flex;
    padding: 8px;
    align-items: center;
    // gap: 10px;
    // margin-bottom: 6px;
    align-self: stretch;
    span {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .user_type_col {
      border-radius: 8px;
      background: #fff;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      span {
        text-align: center;
      }
    }
  }
  .invitation_color {
    background-color: #fae7b6 !important;
  }
  .add_new_user_row {
    margin-top: 30px;
    .add_user_div {
      // cursor: pointer;
      display: flex;
      justify-content: space-between;
      .pending_request_text_div {
        color: black !important;
        display: flex;
        span {
          color: #071135;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .yellow_color_div {
          height: 17px !important;
          width: 17px !important;
          background-color: #fae7b6 !important;
          margin-right: 5px;
        }
      }

      span {
        color: var(--blues-blue-600, #1638b1);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
