.user_management_container {
  .title_heading {
    background-image: url("../../Assets/user_mangement/cover.svg");
    min-height: 261px;
    padding: 100px 22px;
    background-size: cover;
    .user_gray_text {
      color: var(--greys-grey-800, #858689) !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal !important;
    }
    h2 {
      color: #fff;
      font-family: Arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0px;
    }
    p {
      display: inline;
      margin: 0px;
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    span {
      color: var(--states-caution-yellow, #fcc009);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }
 
  .createBtn {
    border-radius: 8px;
    background: #f9cf66 !important;
    height: auto !important;
    padding: 10px 20px !important;
    width: 137px !important;
    color: #151d39 !important;
    border: none !important;
    text-align: center;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media screen and (max-width: "767px") {
    .createBtn-sm {
      width: 100% !important;
      display: flex;
      height: 40px;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--yellows-yellow-300, #f9cf66);
      color: var(--blues-blue-800, #091647);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.table-dropdown {
  // padding: 12px 8px !important;
  .column_hover {
    &:hover {
      background: var(--blues-blue-100, #e3e9fc);
    }
  }
  .padding_on_bottom {
    border-radius: 0px 0px 8px 8px;
  }
  .padding_on_top {
    border-radius: 8px 8px 0px 0px;
  }
}
.ant-dropdown-menu {
  padding: 0 !important;
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
}
.user_management_form_container {
  background-color: #fff;
  height: 100%;
  .form_holder {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    padding: 24px;
  }
  .ant-input {
    border-radius: 8px;
    border: 1px solid var(--greys-grey-500, #bcc3d0);
    height: 40px;
  }
  // .ant-select {
  // }
  .ant-select-selector {
    display: flex;
    border: none !important;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
    height: 40px !important;
    display: flex;
    align-items: center;
  }
  .emailInput {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 42px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    // margin-right: ;
    line-height: normal;
  }
  .PhoneInput {
    background: red;
    // border: 2px solid red;
    border-right: none !important;
    border-radius: 8px 0px 0px 8px !important;
    & input {
      // background: transparent;
      color: black !important;
      border: none;
      pointer-events: none;
      cursor: not-allowed !important;
      &:focus {
        outline: none;
      }
    }
    & select {
      color: black;
    }
  }
  // .ant-select-selection-item {
  //   border-radius: 8px;
  //   border: 1px solid var(--yellows-yellow-300, #f9cf66);
  //   background: var(--yellows-yellow-200, #fae7b6);
  //   display: flex;
  //   align-items: center;
  //   padding: 4px 8px;
  // }
  .permission_container {
    display: flex;
    margin-top: 24px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    padding: 24px;
    .mb-24 {
      margin-bottom: 24px;
    }
    h2 {
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
      margin: 0px;
    }
    span {
      color: var(--character-title-85, rgba(0, 0, 0, 0.85));
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px; /* 66.667% */
    }
  }
  .registerBtnItem {
    margin-bottom: 0px !important;
  }
  .registerBtn {
    border-radius: 8px;
    border: 1px solid var(--blues-blue-700, #122d8e);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    display: flex;
    width: 166px;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 0px !important;
    span {
      color: var(--blues-blue-700, #122d8e);
      text-align: center;

      /* Body text/Bold/B2 Bold */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .registerBtn_inactive{
    border-radius: 8px;
border: 1px solid var(--blues-blue-200, #C8D2F8);
background: var(--greys-primary-white, #FFF);
box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
display: flex;
width: 166px;
height: 40px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 5px;
span{
  color: var(--blues-blue-200, #C8D2F8);
text-align: center;
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
  }
  @media screen and (max-width: 767px) {
    .form_holder {
      border: none;
      padding: 0px;
    }
    .permission_container {
      border: none;
      padding: 0px;
      gap: 20px;
    }
    .go-back {
      color: #091647;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .registerBtn {
      width: 100% !important;
    }
  }
}
//user-management-tooltip
.tooltip_user_management_row {
  padding: 5px !important;
  span {
    color: var(--greys-primary-white, #fff);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .count_left {
    color: var(--greys-primary-white, #fff);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px !important;
  }
}
.permission_card {
  .card_title {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid var(--blues-blue-100, #e3e9fc);
    background: var(--blues-blue-100, #e3e9fc);
    padding: 20px;
    display: flex;
    gap: 10px;
  }
  .card_content {
    border-radius: 0px 0px 15px 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    padding: 20px;
  }
  .card-text {
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 18px !important;
  }
  .card-title-heading {
    color: var(--blues-blue-900, #071135);
    display: inline;
    margin: 0px;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 66.667% */
  }
  @media screen and (max-width: 1200px) {
    .card-title-heading {
      font-size: 14px;
    }
    .card_content {
      padding: 12px;
    }
    .card_title {
      padding: 12px;
    }
  }
}

.select-campaign-modal {
  .select-campaign-select {
    width: 100%;
  }
  .ant-select-dropdown {
    margin-top: 80px !important;
  }
  .ant-select {
    .ant-select-selector {
      min-height: 40px;
      display: flex;
      align-items: center;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);

      .ant-select-selection-placeholder {
        color: var(--greys-grey-600, #a7aebb) !important;
        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        width: 100% !important;
      }
    }
    .ant-select-selection-search-input {
      height: 40px;
    }
  }
  .mt-24 {
    margin-top: 24px;
  }
  .gap-20 {
    gap: 20px;
  }
  .doneBtn {
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-white, var(--greys-primary-white, #fff));
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .cancelBtn {
    border-radius: 8px;
    border: 1px solid var(--blues-blue-600, #1638b1);
    background: var(--greys-primary-white, #fff);
    padding: 10px 20px;
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selection-item {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user_registered_success_modal {
  .custom-color {
    color: black !important;
    margin-top: 30px;
  }
  .ant-modal {
    padding: 24px;
  }
  h3 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  p {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
  }
  span {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
//delete User Modal css
.deleteUserModalMainModal {
  .ant-modal-title {
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .desc {
    color: var(--blues-blue-900, #071135);

    /* Body text/B1 */
    font-family: Arial !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    span {
      color: var(--blues-blue-900, #071135) !important;
      font-family: Arial !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal !important;
    }
  }
}
.user_management_table_box {
  width: 100%;
  margin-top: 28px;
  background: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #cecece;
  border: 1px solid #e4e8ed !important;
  .ant-table-title {
    border-bottom: 1px solid #cecece !important;
    padding: 16px 16px !important;
    background-color: #f3f5f9 !important;
  }
  th {
    background: #fff !important;
    border-bottom: 1px solid #cecece !important;
    color: #1b2444 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  th::before {
    height: 0px !important;
  }
  td {
    background: #fff !important ;
    border-bottom: 1px solid #cecece !important;
    color: #1b2444 !important;
    font-family: Arial;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  .delete {
    color: #9ca9d8;
    text-align: center;

    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .searchBox {
    border-radius: 8px !important;
    border: 1px solid #e4e8ed !important;
    background: #f3f5f9 !important;
    color: #9ca9d8 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // max-width: 399px;
    // min-width: 300px;
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: 8px !important;
    }
    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-radius: 8px !important;
    }
    .ant-input-affix-wrapper {
      border: 1px solid #e4e8ed !important;
      background: #fff !important;
      color: #9ca9d8 !important;
      height: 40px !important;
      input {
        background: #fff !important;
        color: #9ca9d8 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ant-input-group-addon {
      display: none !important;
    }

    .ant-checkbox .ant-checkbox-inner {
      border-color: #9ca9d8 !important;
      background: #e4e8f6 !important;
    }
    .ant-checkbox .ant-checkbox-inner:hover {
      border-color: #9ca9d8 !important;
      background: #e4e8f6 !important;
    }
    .ant-checkbox-inner::after {
      background: #384b8e !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #384b8e !important;
      border-color: #384b8e !important;
    }

    .ant-switch-inner {
      background: #9ca9d8 !important;
    }
    .ant-switch-checked .ant-switch-inner {
      background: #384b8e !important;
    }
  }

  .numberOfUsersDiv {
    border-radius: 8px;
    border: 1px solid var(--greys-grey-400, #cdd2dc);
    background: var(--greys-grey-100, #f8f9ff);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;
    margin-right: 20px;
    span {
      color: var(--BG-Blue, #1b2444);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}