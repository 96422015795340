.profile_container {
  padding: 20px 20px 120px 20px;
  // margin-bottom: 70px;
  height: 100vh !important;
  overflow-y: scroll;
  .user_detail_container {
    padding: 24px !important;
    border: 1px solid #e4e8ed;
    border-radius: 15px;
  }
  .horizontal_line {
    border-top: 1px solid #e4e8ed;
  }
  .heading {
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  .icon_margin {
    margin-left: 10px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #091647 !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  & label {
    color: #071135;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input {
    color: #091647 !important;
    height: 40px !important;
    /* Body text/B2 */
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .searchInput .ant-input {
    height: auto !important;
  }
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    color: #071135;
  }
  .title_value {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    color: #071135;
    word-break: break-all;
    img{
      height: 64px !important;
      width: 64px !important;
      object-fit: cover  ;
      .ant-image-mask{
        &:hover{

          border-radius: 8px !important;
        }
      }
    }
  }
  .company_title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 12px;
    color: #071135;
    margin-top: 10px;
  }
  .marketing_title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.4px;
    color: #071135;
    margin-bottom: 5px;
  }
  .marketing_value {
    margin-bottom: 0px;
    color: #a7aebb;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 16.1px;
  }
  .switch_Col {
    display: flex;
    align-items: center;
  }
  .personal_detail_container {
    border: 1px solid #e4e8ed;
    border-radius: 8px;
    .form_title_container {
      padding: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 12px;
      color: #071135;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #e4e8ed;
      .form_title {
        margin-bottom: 0px;
      }
    }
    .form_container {
      margin: 20px;
    }
  }
  .permission_container {
    border: 1px solid #e4e8ed;
    border-radius: 8px;
    margin-top: 20px;
    .permission_row {
      display: flex;
      justify-content: center;
    }
    .permission_col {
      margin: 20px 20px 0px 20px;
    }
    .form_title_container {
      padding: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 12px;
      color: #071135;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #e4e8ed;
      .form_title {
        margin-bottom: 0px;
      }
    }
    .form_container {
      margin: 20px;
    }
  }
  .company_detail_container {
    border: 1px solid #e4e8ed;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 40px !important;
    .form_title_container {
      padding: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 12px;
      color: #071135;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #e4e8ed;
      .form_title {
        margin-bottom: 0px;
      }
    }
    .form_container {
      margin: 20px;
    }
  }
  .emailInput {
    border-radius: 8px !important;
    border: 1px solid #a7aebb !important;
    background: transparent !important;
    height: 42px !important;
    padding: 6px 12px !important;
    color: #f3f5f9 !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    // margin-right: ;
    line-height: normal;
  }
  .PhoneInput {
    background: red;
    // border: 2px solid red;
    border-right: none !important;
    border-radius: 8px 0px 0px 8px !important;
    & input {
      // background: transparent;
      color: black !important;
      border: none;
      pointer-events: none;
      cursor: not-allowed !important;
      &:focus {
        outline: none;
      }
    }
    & select {
      color: black;
    }
  }
  @media screen and (max-width: "767px") {
    max-width: 100vw !important;
    .heading {
      font-size: 20px !important;
    }
    .title {
      font-size: 12px !important;
      color: var(--greys-grey-800, #858689) !important;
    }
    .title_value {
      font-size: 14px !important;
    }
    .company_title {
      font-size: 16px !important;
    }
    .marketing_value {
      font-size: 12px !important;
    }
    //table css
    .table_col {
      margin-top: 20px;
      max-width: 100vw !important;
      overflow-x: scroll !important;
    }
  }
}
.permission_modal {
  width: 392px !important;
  padding: 24px !important;
  .para {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-modal-title {
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
}
.buttonCol {
  display: flex;
  justify-content: flex-end;
  .button_cancel {
    display: flex;
    width: 166px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    box-shadow: none;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #091647;
    &:hover {
      color: #091647 !important;
    }
  }
  .button_update {
    display: flex;
    width: 166px;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-700, #122d8e);
    background: var(--greys-primary-white, #fff);
    box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
    color: var(--blues-blue-700, #122d8e);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      border: 1px solid var(--blues-blue-700, #122d8e) !important;
      background: var(--greys-primary-white, #fff) !important;
      box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12) !important;
      color: var(--blues-blue-700, #122d8e) !important;
    }
  }
  .modal_cancel {
    border-radius: 8px;
    margin-right: 10px;
    border: 1px solid var(--blues-blue-600, #1638b1);
    background: var(--greys-primary-white, #fff);
    color: #091647;
    font-family: Arial;
    &:hover {
      color: #091647 !important;
      border: 1px solid var(--blues-blue-600, #091647) !important;
    }
  }
  .modal_yes {
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border: 1px solid var(--blues-blue-600, #091647);
    color: #fff;
    font-family: Arial;
    &:hover {
      background: var(--blues-blue-800, #091647) !important;
      color: #fff !important;
      border: 1px solid var(--blues-blue-600, #091647) !important;
    }
  }
}

.switch_toggle .ant-switch-inner {
  background-color: #c8d2f8 !important;
}
