.jobAdvertMainDiv {
  .jobAdvertTableMainDiv {
    width: 100%;
    margin-top: 28px;
    background: #fff;
    border-radius: 15px;
    .ant-table-title {
      border-bottom: 1px solid #cecece !important;
      padding: 16px 16px !important;
      background-color: #f3f5f9 !important;
    }
    th {
      background: #fff !important;
      border-bottom: 1px solid #cecece !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    th::before {
      height: 0px !important;
    }
    td {
      background: #fff !important ;
      border-bottom: 1px solid #cecece !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .delete {
      color: #9ca9d8;
      text-align: center;

      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .searchBox {
      border-radius: 8px !important;
      border: 1px solid #e4e8ed !important;
      background: #f3f5f9 !important;
      color: #9ca9d8 !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // max-width: 399px;
      // min-width: 300px;
      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-affix-wrapper {
        border: 1px solid #e4e8ed !important;
        background: #fff !important;
        color: #1638b1 !important;
        height: 40px !important;
        input {
          background: #fff !important;
          color: #1638b1 !important;
          font-family: Arial;
          font-size: 16px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .ant-input-group-addon {
        display: none !important;
      }

      .ant-checkbox .ant-checkbox-inner {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox .ant-checkbox-inner:hover {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox-inner::after {
        background: #384b8e !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #384b8e !important;
        border-color: #384b8e !important;
      }

      .ant-switch-inner {
        background: #9ca9d8 !important;
      }
      .ant-switch-checked .ant-switch-inner {
        background: #384b8e !important;
      }
    }
  }
}
