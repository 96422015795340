.report_modal_container {
  .ant-modal-title {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-modal-content {
    padding: 24px;
    border-radius: 20px;
    background: #fff;

    h4 {
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: inline-block;
      margin: 0px;
    }
    p {
      margin: 0px;
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      display: inline-block;
      line-height: normal;
    }
  }

  .cancelBtn {
    color: var(--primary-white, var(--greys-primary-white, #fff));
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .okBtn {
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-600, #1638b1);
    background: var(--greys-primary-white, #fff);
  }
}

.status_modal_container {
  .ant-modal-content {
    border-radius: 8px;
    background: #fff;

    p {
      margin: 0px;
      display: block;
      color: var(--greys-grey-800, #858689);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .radioBtn {
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .cancelBtn {
    color: var(--primary-white, var(--greys-primary-white, #fff)) !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .okBtn {
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-600, #1638b1);
    background: var(--greys-primary-white, #fff);
  }
}

.transfer_modal_container {
  .ant-modal-content {
    border-radius: 8px;
    background: #fff;
    .radioBtn {
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  p {
    margin: 0px;
    color: var(--text-primary, #071135);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  // select feild
  .ant-select {
    .ant-select-selector {
      height: 40px;
      display: flex;
      align-items: center;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);

      .ant-select-selection-placeholder {
        color: var(--greys-grey-600, #a7aebb) !important;
        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        width: 100% !important;
      }
    }
    .ant-select-selection-search-input {
      height: 40px;
    }
  }
  .cancelBtn {
    color: #fff !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: #091647 !important;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    padding: 10px 20px !important;
    height: auto !important;
    display: flex;
    align-items: center;
  }
  .okBtn {
    color: #1638b1 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #1638b1;
    padding: 10px 20px !important;
    height: auto !important;
    background: #fff !important;
  }
  .radio-holder {
    padding: 20px;
    border-radius: 8px;
    background: var(--greys-primary-white, #fff);
    box-shadow: 4px 0px 35px 0px #e7edf8;
    max-height: 200px !important;
    overflow-y: auto !important;
    .radioItem:not(:last-child) {
      margin-bottom: 16px !important;
    }
  }
  .mt-24 {
    margin-top: 24px;
  }
}

.update_alert_modal_container {
  .ant-modal-content {
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
      0px 4px 22px 3px rgba(0, 0, 0, 0.15);

    span {
      color: var(--greys-grey-200, #f3f5f9);

      /* Body text/B3 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
