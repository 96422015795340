.nonProtectedLayoutContainer {
  background: #fff !important;
  min-height: 100vh !important;
  .logo-svg {
    width: 164.909px;
    height: 35px;
  }
  .main_header {
    background: #091647 !important;
    .navbar {
      height: 88px;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .pageTitle {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .nonProtectedContentContainer {
    padding: 0px 24px 0px 24px;
    max-width: 1222px;
    margin: auto;
  }
  @media screen and (max-width: 767px) {
    .logo-svg {
      width: 130px;
      height: 28px;
      margin-left: -20px;
    }
    .dp-none-sm {
      display: none;
    }
  }
}
