/**********news page************/
.newsMainContainer {
  .newsTypeHeading {
    color: #071135 !important;
    font-family: Arial;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  @media screen and (max-width: "767px") {
    .react-multi-carousel-item {
      // display: flex;
      width: 252px;
      // height: 313px;
      // padding-bottom: 0px;
      // flex-direction: column;
      // align-items: flex-start;
      // gap: 24px;
    }
    .newsTypeHeading {
      font-size: 20px !important;
    }
  }
}

.newsCardMainContainer {
  border-radius: 8px;
  border: 1px solid #e4e8ed;
  height: 380px;
  margin: 10px 10px 10px 10px;
  .newImgContainer {
    border-radius: 8px 8px 0px 0px;
    height: 179px;

    img {
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      height: 179px;
      user-select: none !important;
    }
  }
  .newsCardContentBox {
    padding: 24px;
    .newsHeading {
      color: #071135 !important;
      font-family: Arial;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 700;
      user-select: none !important;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      line-height: normal;
      margin-top: 0px !important;
    }
    .newsDate {
      color: #858689 !important;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      user-select: none !important;
      margin-top: 10px;
      line-height: normal;
    }
    .newsDesc {
      overflow: hidden;
      color: var(--blues-blue-900, #071135);
      text-overflow: ellipsis;
      /* Body text/B3 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @media screen and (max-width: 767px) {
      .newsHeading {
        font-size: 16px !important;
        word-break: break-all;
      }
      .newsDesc{
        padding-bottom: 10px;
      }
    }
  }
}

/**********news details container************/
.newsDetailsMainContainer {
  height: 100%;
  overflow-y: scroll;
  padding: 20px;

  .newsHeading{
    padding: 22px 0px 11px 0px;
    color: var(--blues-blue-900, #071135);
    /* Heading/H2 */
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .newsDateDetail{
    padding: 11px 0px 22px 0px;
    color: var(--blues-blue-900, #071135);
    /* Body text/B2 */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /********* Backed data styling************/
  .backendData {
    h1,
    h2,
    h3,
    h4,
    p,
    b,
    i {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
    }
    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
    strong {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      line-height: normal;
    }
    @media screen and (max-height: 767px) {
      p {
        font-size: 14px;
      }
      .newsDetailsMainContainer{
        height: 100%;
        overflow-y: scroll;
        // padding: 20px !important;
        .newsHeading{
          font-size: 20px;
        }
      }
    }
  }

  .sm-back-heading {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .newsDetails-banner {
    width: 100%;
    height: 282px;
    object-fit: cover;
  }
  .trending-news-img {
    width: 100%;
    margin-bottom: 20px;
    height: 142px;
    border-radius: 8px;
    object-fit: cover;
  }
  .heading-h2 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .heading-h3 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .heading-h4 {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }
  .body-text {
    overflow: hidden;
    color: var(--blues-blue-900, #071135);
    text-overflow: ellipsis;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .date-text {
    color: var(--greys-grey-800, #858689);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .trending-body-text {
    overflow: hidden;
    color: var(--blues-blue-900, #071135);
    text-overflow: ellipsis;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
  .trending-news-container {
    position: sticky;
    top: 1px;
    height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .display-none-lg {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  /**********news page************/

  /**********news details container************/
  .newsDetailsMainContainer {
    height: 100%;
    padding: 20px;
    .newsDetails-banner {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
    }
    .trending-news-img {
      // width: 252px;
      height: 159px;
      object-fit: cover;
    }
    .heading-h2 {
      font-size: 20px !important;
    }
    .body-text {
      font-size: 14px;
    }
    .heading-h3 {
      font-size: 20px;
    }
    .heading-h4 {
      font-size: 16px;
    }
    .date-text {
      font-size: 12px;
    }
    .trending-body-text {
      font-size: 14px;
    }
    .trending-news-cards {
      border: 0px solid red;
      // width: 252px !important;
      // height: 337px !important;
    }
    .display-none-lg {
      display: block;
    }
  }
}

.newsModalSearch {
  .modal-search {
    border-radius: 8px !important;
    border: 1px solid #e4e8ed !important;
    background: #fff !important;
    color: #9ca9d8 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    .ant-input-affix-wrapper {
      border: 1px solid #e4e8ed !important;
      background: #fff !important;
      color: #9ca9d8 !important;
      height: 40px !important;
      input {
        background: #fff !important;
        color: #9ca9d8 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .ant-btn-primary {
    border-radius: 8px;
    background: var(--blues-blue-800, #091647);
    height: 40px;
    padding: 10px 20px;
    color: var(--Primary-White, var(--greys-primary-white, #fff));
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      background: var(--blues-blue-800, #091647) !important;
      color: var(--Primary-White, var(--greys-primary-white, #fff)) !important;
    }
  }
  .ant-btn-default {
    height: 40px;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid var(--blues-blue-600, #1638b1);
    background: var(--greys-primary-white, #fff);
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    &:hover {
      border: 1px solid var(--blues-blue-600, #1638b1) !important;
      background: var(--greys-primary-white, #fff) !important;
      color: var(--blues-blue-600, #1638b1) !important;
    }
  }
  .modal-card-parent {
    overflow-y: auto;
    max-height: 365px;
  }
  .modal-cards {
    border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
    background: var(--Color-2, #fff);
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 10px;
    margin-top: 8px;
    border-radius: 5px;
  }
  .modal-text {
    color: var(--Text-Primary, #071135);

    /* Body text/B2 */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
