.create-advert-container {
  .custom-padding {
    padding: 10px 60px !important;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }
  .form-caution {
    display: flex;
    justify-content: space-between;
    padding: 28px 24px;
    .desc_mobile {
      display: none;
    }
    .caution {
      color: var(--blues-blue-900, #071135);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .tab-btn-holder {
      display: flex;
      box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.09);
      justify-content: flex-end;
      .active {
        background-color: rgba(22, 56, 177, 1);
        padding: 9px;
      }
      .inactive {
        padding: 9px;
        background-color: rgba(200, 210, 248, 1);
      }
    }
  }
  .office-form-holder {
    margin: 38px 0px 24px 0px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    h3 {
      margin: 0px;
      color: var(--text-primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }
    p {
      margin: 0px;
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    // ant input
    .ant-input {
      height: 40px;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
    }
    .ant-input::placeholder {
      color: var(--greys-grey-600, #a7aebb) !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }
  }
  .advert-head-count .ant-input-number-input-wrap {
    width: 100%;
  }
  .advert-detail-form-container {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    .salary_rate_input {
      // border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
      border: none !important;
      border-radius: 8px;
      &:hover {
        // border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
        border: none !important;
      }
      .ant-input-group-addon {
        padding: 0 !important;
      }
      .dollar_icon {
        padding: 0 10px;
      }
      .select_input {
        min-width: 200px !important;
        .ant-select-selection-item {
          padding: 0 !important;
        }
        &:hover {
          border: none !important;
        }
      }
    }

    .calendar-icon {
      position: absolute;
      top: 49%;
      left: 15px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .pb-15 {
      padding-bottom: 15px !important;
    }

    // ant checkbox
    .custom-checkbox {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .ant-checkbox-inner {
        background-color: rgba(227, 233, 252, 1);
        border: 2px solid rgba(172, 188, 245, 1);
        width: 20px !important;
        height: 20px !important;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: rgba(22, 56, 177, 1) !important;
        border: 2px solid rgba(172, 188, 245, 1);
        width: 20px !important;
        height: 20px !important;
      }
    }

    // ant input
    .ant-input {
      height: 40px;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
    }
    .ant-input::placeholder {
      color: var(--greys-grey-600, #a7aebb) !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }

    // ant input group
    .ant-input-group {
      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
      height: 40px;
      .ant-input {
        border: none;
        height: 40px;
        &:focus {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selection-item::after {
        content: url("../../Assets/downArrowIcon.svg");
        visibility: visible;
        position: relative;
        top: 5px;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-input-group-addon:first-child {
      border-radius: 8px;
      border: 1.5px solid var(--tertiary-blue, #ebeef7);
      background: var(--tertiary-blue, #ebeef7);
      color: var(--blues-blue-600, #1638b1);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
    .ant-input-group .ant-input-group-addon .ant-select {
      width: 100% !important;
    }
    .ant-input-group-addon:last-child {
      width: 200px !important;
      border: none;
      background-color: #fff;
      border-left: 1px solid var(--greys-grey-500, #bcc3d0);
      .ant-select .ant-select-selector {
        color: var(--blues-blue-800, #091647);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    // select feild
    .ant-select {
      .ant-select-selector {
        height: 40px;
        display: flex;
        align-items: center;

        border-radius: 8px;
        border: 1px solid var(--greys-grey-500, #bcc3d0);

        .ant-select-selection-placeholder {
          color: var(--greys-grey-600, #a7aebb) !important;
          font-family: Arial !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          width: 100% !important;
        }
      }
      .ant-select-selection-search-input {
        height: 40px;
      }
    }

    // ant picker
    .ant-picker-input {
      margin-left: 22px;
    }
    .ant-picker {
      height: 40px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
    }
  }
  .advert-detail-form-container_1 {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: #fff;
    .salary_rate_input_1 {
      .ant-input-group-addon {
        padding: 0 !important;
      }
      .dollar_icon {
        padding: 0 10px;
      }
      .select_input {
        width: 200px !important;
        .ant-select-selection-item {
          padding: 0 !important;
        }
      }
    }
    .salary_rate_input {
      .ant-input-group-addon {
        padding: 0 !important;
      }
      .dollar_icon {
        padding: 0 10px;
      }
      .select_input {
        width: 200px !important;
        .ant-select-selector {
          border: none !important;
        }
        .ant-select-selection-item {
          padding: 0 !important;
        }
      }
    }

    .calendar-icon {
      position: absolute;
      top: 49%;
      left: 15px;

      transform: translateY(-50%);
      pointer-events: none;
    }

    .pb-15 {
      padding-bottom: 15px !important;
    }

    // ant checkbox
    .custom-checkbox {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .ant-checkbox-inner {
        background-color: rgba(227, 233, 252, 1);
        border: 2px solid rgba(172, 188, 245, 1);
        width: 20px !important;
        height: 20px !important;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: rgba(22, 56, 177, 1) !important;
        border: 2px solid rgba(172, 188, 245, 1);
        width: 20px !important;
        height: 20px !important;
      }
    }

    // ant input
    .ant-input {
      height: 40px;

      border-radius: 8px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
    }
    .ant-input::placeholder {
      color: var(--greys-grey-600, #a7aebb) !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }

    // ant input group
    .ant-input-group {
      border-radius: 8px;
      // border: 1px solid var(--greys-grey-500, #bcc3d0);
      height: 40px;

      .ant-input {
        border: none;
        height: 40px;
        &:focus {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selection-item::after {
        content: url("../../Assets/downArrowIcon.svg");
        visibility: visible;
        position: relative;
        top: 5px;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-input-group-addon:first-child {
      border-radius: 8px;
      border: 1.5px solid var(--tertiary-blue, #ebeef7);
      background: var(--tertiary-blue, #ebeef7);
      color: var(--blues-blue-600, #1638b1);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
    .ant-input-group-addon:last-child {
      border: none;
      background-color: #fff;
      border-left: 1px solid var(--greys-grey-500, #bcc3d0);
      .ant-select .ant-select-selector {
        color: var(--blues-blue-800, #091647);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    // select feild
    .ant-select {
      .ant-select-selector {
        height: 40px;
        display: flex;
        align-items: center;

        border-radius: 8px;
        border: 1px solid var(--greys-grey-500, #bcc3d0);

        .ant-select-selection-placeholder {
          color: var(--greys-grey-600, #a7aebb) !important;
          font-family: Arial !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          width: 100% !important;
        }
      }
      .ant-select-selection-search-input {
        height: 40px;
      }
    }

    // ant picker
    .ant-picker-input {
      margin-left: 22px;
    }
    .ant-picker {
      height: 40px;
      border: 1px solid var(--greys-grey-500, #bcc3d0);
    }
  }

  .requirement-common-item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    padding: 12px 8px;
    border-radius: 8px;
    border: 1px solid var(--greys-grey-500, #bcc3d0);
    p {
      margin: 0px;
    }
    .active {
      color: var(--blues-blue-600, #1638b1);
      font-family: Arial !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .inactive {
      color: var(--greys-grey-800, #858689);
      font-family: Arial !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .hard-soft-req-main-container {
    padding: 24px;
    .text-center {
      display: flex;
      text-align: center;
    }
    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin: 0px;
      line-height: normal;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
    }

    .dropable-area {
      border-radius: 8px;
      width: 50%;
      border: 1px dashed var(--primary-blue, #1638b1);
      background: var(--greys-grey-200, #f3f5f9);
      padding: 16px 10px;
      width: 100%;
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;

      p {
        color: var(--blues-blue-900, #071135);
        font-family: Arial !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin: 0px;
        line-height: 24px; /* 150% */
      }
    }
  }
  .add-requirement-main-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 24px;
    .cancelBtn {
      min-width: 162px;
    }
    .single-item-title {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      margin-bottom: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .selected_requirement_main_div {
      height: 100%;
      border-radius: 12px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      .selected_requirement_inner_div {
        padding: 24px 0 0px 24px;
        overflow-y: auto;
        .title-heading {
          color: #1e1e1e;
          margin-bottom: 42px;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .p-lr-24 {
          padding: 0 20px;
        }
        .selected_req {
          // height: 550px;
          // overflow: auto;
          // overflow-x: hidden;
        }
      }
    }

    .post-advert-btn {
      margin-top: 26px;
      display: flex;
      gap: 16px;
    }

    .mb-46 {
      margin-bottom: 46px;
    }

    .search-input {
      // height: 36px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--blues-blue-200, #c8d2f8);
      background-color: #f3f5f9 !important;
      ::placeholder {
        color: var(--greys-grey-700, #8f939b);
        font-family: Arial !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ant-input {
      background-color: #f3f5f9 !important;
      color: var(--greys-grey-700, black);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.5px;
    }
    .ant-input::placeholder {
      background-color: #f3f5f9 !important;
      color: var(--greys-grey-700, #8f939b);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.5px;
      height: 100%;
    }

    .soft-hard-box {
      // width: 35vw;
      // height: 654px;
      height: 100%;
    }

    @media screen and (max-width: 1145px) {
      .soft-hard-box {
        width: 25vw;
      }
    }
    @media screen and (min-width: 1146px) and (max-width: 1200px) {
      .soft-hard-box {
        width: 30vw;
      }
    }

    .box {
      border-radius: 12px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: var(--greys-primary-white, #fff);
      padding: 0px;
      height: 100%;
      .search_requirement_div {
        padding: 24px;
        border-radius: 12px 12px 0px 0px;
        border-top: 1px solid var(--greys-grey-300, #e4e8ed);
        border-right: 1px solid var(--greys-grey-300, #e4e8ed);
        border-left: 1px solid var(--greys-grey-300, #e4e8ed);
        background: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
      }
    }
  }

  .searchable-requirement-item {
    .draggable-icon {
      position: relative;
      top: 32px;
      width: 30px !important;
      z-index: 100;
      left: 10px;
    }
    // select feild

    .withoutSelectBox {
      padding-left: 40px;
      border-radius: 4.673px;
      border: 0.584px solid var(--greys-grey-400, #cdd2dc);
      background: var(--greys-grey-100, #f8f9ff);
      display: flex;
      align-items: center;
      min-height: 44px;
      color: #071135;
      // justify-content: center
      p {
        margin: 0 !important;
        padding: 0 !important;
        margin-top: -3px !important;
        color: #071135;
        font-family: Arial !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ant-select {
      .ant-select-selection-overflow-item {
        // padding-left: 6px !important;
      }
      .ant-select-selector {
        padding-left: 40px;
        border-radius: 4.673px;
        border: 0.584px solid var(--greys-grey-400, #cdd2dc);
        background: var(--greys-grey-100, #f8f9ff);
        display: flex;
        align-items: center;
        min-height: 44px;
        // padding-left: 32px !important;
        input {
          padding-left: 24px !important;
        }
        .ant-select-selection-placeholder {
          color: var(--bg-blue, #1b2444);
          font-family: Arial !important;
          font-size: 16px;
          height: 100%;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 400;
          line-height: 14.02px; /* 150% */
          width: 100% !important;
        }
      }
      .ant-select-selection-search-input {
        min-height: 40px;
      }
    }
    .multiple_selected_add {
      .ant-select-selection-placeholder {
        padding-left: 30px;
        color: var(--bg-blue, #1b2444);
        font-family: Arial !important;
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        line-height: 14.02px; /* 150% */
        width: 100% !important;
      }
      .ant-select-selector {
        // padding-left: 40px;
        border-radius: 4.673px;
        border: 0.584px solid var(--greys-grey-400, #cdd2dc);
        background: var(--greys-grey-100, #f8f9ff);
        display: flex;
        align-items: center;
        min-height: 44px;
        padding-left: 32px !important;
        input {
          padding-left: 0px !important;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .form-caution {
      padding: 30px 0 !important;
      .caution {
        font-size: 14px !important;
      }
      .desc_mobile {
        display: flex !important;
        .desc {
          text-align: start !important;
        }
      }
      .desc_laptop {
        display: none !important;
      }
    }
    .advert-detail-form-container {
      padding: 0 !important;
      border: none !important;
      .salary_input_rate_phone_col {
        .ant-input {
          border-bottom: none !important;
          border-radius: 8px 8px 0 0 !important;
        }
      }
      .select_input_phone {
        .ant-select-selector {
          border-radius: 0 0 8px 8px !important;
        }
      }
      .range_input_box_div{
        .upper_col{
          .ant-input {
            border-radius: 8px 8px 0px 0px !important;
            border-bottom: none !important;
          }
        }
        .lower_col{
          .ant-input {
            border-radius: 0 !important;
            border-bottom: none !important;

          }
        }
      }
    }
    .advert-detail-form-container_1 {
      .salary_input_rate_phone_col {
        .ant-input {
          border-bottom: none !important;
          border-radius: 8px 8px 0 0 !important;
        }
      }
    }
  }
}
