.add_campaign_main_container {
  // border: 1px solid red;
  .w-100 {
    width: 100%;
  }
  .banner_img_col .ant-image {
    width: 100% !important;
    height: auto;
  }
  .ant-form-item .ant-form-item-label > label {
    color: var(--blues-blue-900, #071135) !important;
    font-family: Arial !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .form_main_div {
    margin-top: 36px;
    .form_div {
      padding: 24px;
      border-radius: 12px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      .ant-input {
        border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
        &:hover {
          border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
        }
      }
      .ant-select {
        .ant-select-selector {
          border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
          outline: none !important;
        }
      }
      .add_user {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 8px;
        span {
          color: var(--blues-blue-600, #1638b1);
          /* Body text/Bold/B3 Bold */
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .form_input_div {
        cursor: pointer;
        display: flex;
        min-height: 40px;
        padding: 0px 10px;
        gap: 10px;
        align-items: center;
        align-self: stretch;
        color: var(--blues-blue-900, #071135);
        border: 1px solid var(--greys-grey-500, #bcc3d0);
        border-radius: 8px;
        background-color: white;
        .add_user_placeholder {
          color: var(--greys-grey-600, #a7aebb);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .count_div {
          border-radius: 8px;
          border: 1px solid var(--greys-grey-400, #cdd2dc);
          background: var(--greys-primary-white, #fff);
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 7px;
          span {
            color: var(--BG-Blue, #1b2444);
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    .submit_button_row {
      margin-top: 24px;
      padding: 0 12px 0 24px;
    }
  }
  // for small screen
  @media screen and (max-width: 767px) {
    .go-back {
      color: #091647;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .form_main_div {
      margin-top: 0px;
      .form_div {
        padding: 0px;
        border: none;
        .ant-form-item-label {
          font-size: 16px !important;
        }
      }
    }
    .specialBtn-parent {
      display: flex !important;
      flex-direction: column-reverse !important;
      gap: 15px !important;
      & button {
        width: 100% !important;
      }
      & :nth-child(2) {
        border-radius: 20px !important;
      }
    }
  }
}
.ant-popover-inner {
  border-radius: 4px !important;
  background-color: #091647 !important;
  color: white !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12) !important;
  .ant-popover-inner-content {
    color: var(--greys-primary-white, #fff);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
.ant-popover-arrow::before {
  background-color: #091647 !important;
}
