.permissionCard_mainDiv {
  .title_main_col {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid var(--blues-blue-100, #e3e9fc);
    background: #E3E9FC;
    padding: 20px;
    display: flex;
    gap: 10px;
    display: flex;
    align-items: center;
    span {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }
    .info_icon {
      cursor: pointer;
    }
  }
  .content_row {
    border-radius: 0px 0px 20px 20px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-primary-white, #fff);
    .content_main_div {
      padding: 0 20px 20px 20px;
      .content_item_col {
        margin-top: 18px !important;
        height: 20px;
        span {
          color: var(--blues-blue-800, #091647);
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .content_div_checkbox {
          margin-right: 8px !important;
          .ant-checkbox-inner::after {
            border-color: white !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .title_main_col {
      padding: 14px 12px;
      border-radius: 8px 8px 0px 0px;
      & span {
        font-size: 18px;
      }
    }
    .content_row {
      .content_main_div {
        .content_item_col {
          // border: 2px solid red;
          margin-top: 18px !important;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.permissionCard_mainDiv_deactive {
  .title_main_col {
    border-radius: 15px 15px 0px 0px;
    border: 1px solid var(--blues-blue-100, #e3e9fc);
    background: var(--greys-grey-300, #E4E8ED);
    padding: 20px;
    display: flex;
    gap: 10px;
    display: flex;
    align-items: center;
    span {
      color: var(--greys-grey-600, #A7AEBB);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }
    .info_icon {
      cursor: pointer;
    }
  }
  .content_row {
    border-radius: 0px 0px 20px 20px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-200, #F3F5F9);
    .content_main_div {
      padding: 0 20px 20px 20px;
      .content_item_col {
        margin-top: 18px !important;
        height: 20px;
        span {
          color: #BCC3D0;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .content_div_checkbox {
          margin-right: 8px !important;
          .ant-checkbox-inner::after {
            border-color: white !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .title_main_col {
      padding: 14px 12px;
      border-radius: 8px 8px 0px 0px;
      & span {
        font-size: 18px;
      }
    }
    .content_row {
      .content_main_div {
        .content_item_col {
          // border: 2px solid red;
          margin-top: 18px !important;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
