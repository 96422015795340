.mainLogoutModal{
    .ant-modal-title{
        color: var(--blues-blue-900, #071135);
font-family: Arial;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .logout_desc{
        color: var(--Text-Primary, #071135);

/* Body text/B1 */
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
}