.layout_main_component {
  background: #f2f4f8 !important;
  font-family: Arial !important;
  width: 100vw;
  overflow: hidden;
  .pageTitleBox {
    border-radius: 0px 0px 16px 16px;

    border-radius: 0px 0px 16px 16px;
    padding: 24px;
  }
  .childrenBox {
    padding: 0px 24px 24px 24px;
  }
  .pageTitleBox p {
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #272b39;
    margin: 0 !important;
  }
  .mainSidebar {
    height: 100vh;
    overflow-y: auto;
    background: #091647 !important;
    padding: 0px 12px 12px 12px !important;
  }
  .moreBtnRow {
    padding: 10px 18px 30px 18px !important;
    .showMoreList {
      padding-left: 16px !important;
      .moreLink {
        color: #a7aebb !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 22px !important;
        margin-bottom: 0px !important;
        padding: 0 !important;
        cursor: pointer;
      }
      .activeMenuLink {
        background: #f9cf66 !important;
        font-weight: 700 !important;
        padding: 8px 16px !important;
        border-radius: 5px !important;

        color: #272b39 !important;
      }
    }
  }
  .moreBtn {
    color: #fff;
    /* Body text/Bold/B2 Bold */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .mainContainer {
    max-height: calc(100vh - 100px);
    height: calc(100vh - 100px);
    overflow-y: auto;
    background: #f3f5f9 !important;
    overflow-x: hidden;
    padding: 24px;
  }
  /* this class is specially for search screen */
  .simpleMainContainer {
    height: calc(100vh - 90px);
    overflow: hidden;
    background: #fff !important;
  }
  .white-bg {
    background: #fff !important;
  }

  .ant-menu-light {
    background: transparent !important;
  }
  .menu_link {
    color: #fffcfc !important;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0 !important;
    // line-height: 20px;
    margin-top: 3px !important;
    line-height: normal;
    padding: 0px !important;
  }

  .ant-menu-item {
    border-radius: 0px !important;
    // margin: 0px !important;
    // width: 100% !important;
    // margin-bottom: 16px;
    padding: 0 !important;
    height: 60px !important;
    border-radius: 12px !important;
    svg path {
      stroke: #fff;
    }
  }
  .mainMenuLink {
    display: flex !important;
    align-items: center !important;
    gap: 8px;
    height: 40px !important;
    padding: 30px 14px 30px 14px !important;
  }
  .ant-menu-item-selected {
    .mainMenuLink {
      background: #f9cf66 !important;
      display: flex !important;
      align-items: center !important;
      gap: 8px;
      padding: 30px 14px 30px 14px !important;
      height: 40px !important;
    }

    .menu_link {
      font-weight: 700 !important;
      color: #272b39 !important;
    }
    svg path {
      stroke: #161615 !important;
    }
  }

  .ant-menu-submenu-arrow::after {
    background-color: #fffcfc !important;
  }
  .ant-menu-submenu-arrow::before {
    background-color: #fffcfc !important;
  }
  .logoutBtn {
    border-radius: 5px !important;
    background: #f9cf66 !important;
    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 8px 16px !important;
    height: auto !important;
    color: #fff !important;
    margin-left: 20px;
    width: 200px;
  }
}

.settingDropdown {
  min-width: 260px !important;
  ul {
    padding: auto 0px !important;
    margin-top: 18px !important;
    .ant-dropdown-menu-item {
      padding: 10px 20px !important;
      span {
        color: #071135 !important;

        font-family: Arial !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .ant-dropdown-menu-item:not(:last-child) {
      // padding: 10px 20px !important;
      border-bottom: 0.5px solid #e4e8ed !important;
    }
    .ant-dropdown-menu-item:hover {
      background: #f3f5f9 !important;
    }
  }
}

.main-header {
  background: #091647 !important;
  .navbar {
    height: 88px;
  }
}
.header_input_box_search {
  height: 40px !important;
}
.mobileSearchHeader {
  border-radius: 12px !important;
}
.contentMainContainer {
  width: calc(100vw - 300px);
  .mobile_navbar_parents {
    display: none;
  }
  .pageTitle {
    color: var(--Primary-White, var(--greys-primary-white, #fff));
    /* Body text/B2 */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .profileName {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 !important;
  }
  .designation {
    color: #acbcf5;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 4px 0px 0px 0px !important;
  }

  .user-decs {
    padding-right: 30px;
  }
  .settingIcon {
    height: 24px;
    width: 24px;
    color: #fff;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .pageHeading {
    color: #04040b;
    font-family: Arial;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}
.avatar-container {
  margin-right: 10px;
  .ant-avatar {
    background: #e3e9fc !important;
  }
  .profileAvatarName {
    color: #071135 !important;
    // text-align: center;
    font-family: Arial;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal !important;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;
    text-transform: capitalize !important;
  }
}
// onboarding layout css starts now

.onboarding_layout_container {
  overflow: hidden;
  display: inline-block;
  .onboarding_layout {
    display: inline-block;
    max-height: 100vh;
    min-height: 100vh;
    width: 299px !important;
    background-image: url("../../Assets/layout/onboarding_layout_image.jpg");
    .mainSidebarParent {
      max-width: 300px;
      min-width: 300px;
    }
    .mainSidebarHeader {
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .onboarding_layout_content {
    .white-bg {
      background-color: white !important;
      max-width: 100vw !important;
    }
  }
  .mainSidebar {
    overflow-y: auto;
    background: #091647 !important;
  }
}
.role{
color: var(--Text-Tertiary, #A7AEBB);
/* Body text/B3 */
font-family: Arial;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.userName{
color: var(--Primary-White, var(--greys-primary-white, #FFF));
/* Heading/H3 */
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.title{
  color: var(--Primary-White, var(--greys-primary-white, #FFF));
/* Body text/B2 */
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
align-items: center;
}
.arrowUp{
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.settingTitle{
color: var(--greys-grey-600, #A7AEBB);
/* Body text/B2 */
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
/*==================styles for mobile screen========================*/
@media screen and (max-width: 767px) {
  .layout_main_component {
    .sidebar-menu {
      display: none;
    }
    .mainContainer {
      height: 100vh !important;
      // max-height: 100vh !important;
      width: 100vw !important;
    }
  }

  .contentMainContainer {
    width: 100% !important;
    .mobile_navbar_parents {
      display: block;
    }
    .mobile_navbar {
      align-content: center;
      color: white;
      padding: 0px 15px 0px 15px;
      background: #091647 !important;
      height: 88px;
      .hamberger-icon {
        width: 24px;
        height: 24px;
      }
      .mobile-nav-logo {
        width: 130px;
        height: 28px;
      }
    }
  }

  .main-header {
    display: none;
    .navbar {
      display: none;
    }
  }

  // onboarding layout css starts now

  .onboarding_layout_container {
    .onboarding_layout {
      display: none;
    }
    .onboarding_layout_content {
      width: 100%;
    }
  }
}
.mobileHeaderContainer {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
  .mobileTitle {
    color: #091647 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.drawerMenu{
  display: flex;
  background-color: #091647 !important;
  .closeIcon{
    display: flex;
    justify-content: flex-end;
  }
}
