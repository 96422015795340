.section-progress-container {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--greys-grey-300, #e4e8ed);
  p {
    margin: 0px;
  }
  .active {
    color: var(--bg-blue, #1b2444);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .inactive {
    color: var(--blues-blue-300, #acbcf5);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .progress-bar {
    width: 100%;
    .ant-progress-bg {
      background-color: var(--blues-blue-500, #3d63e7);
    }
  }
}

.onboarding_progerss_container {
  border-radius: 8px;
  border: 1px solid #e4e8ed;
  background: #fff;
  padding: 10px;
  // height: 80px;
  display: flex;
  justify-content: space-around;
  .onboardingProgressBar {
    // height: 4px !important;
    .ant-progress-outer {
      // height: 4px !important;
    }
    .ant-progress-inner {
      height: 4px !important;

      background-color: #d6ddf2 !important;
    }
    .ant-progress-bg {
      background-color: #3d63e7 !important;
      height: 4px !important;
    }
  }
  .active-text {
    text-align: center;
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // border: 2px solid red;
  }
  .normal_text {
    text-align: center;
    color: #acbcf5;
    font-family: Arial;
    // cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // border: 2px solid red;
  }
}
@media screen and (max-width: 1000px) {
  .onboarding_progerss_container {
    height: 100px;
  }
}
@media screen and (max-width: 767px) {
  .onboarding_progerss_container {
    min-width: 700px;
    overflow: hidden;
  }
}
@media screen and (max-width: 767px) {
  .section-progress-container {
    .active,
    .inactive {
      font-size: 14px !important;
      text-align: center !important;
    }
  }
}
