.HardSoftRequirement_container {
  width: 100% !important;
  .tab_main_div {
    border-radius: 12px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--greys-grey-100, #f8f9ff);
    .selectedTab {
      display: flex;
      width: 142px;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background: var(--blues-blue-900, #071135);
      span {
        color: var(--greys-primary-white, #fff);
        text-align: center;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 10px 12px;
      }
    }
    .nonSelectedTab {
      border-radius: 8px;
      background: var(--greys-primary-white, #fff);
      display: flex;
      width: 142px;
      height: 40px;
      padding: 10px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      span {
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
