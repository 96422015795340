@font-face {
  // Defining what the font will be called
  font-family: microftFont;
  // Linking to the font file
  src: url("../font/msuighur.ttf");
}
.app_container {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

body {
  background: #f2f4f8;
  margin: 0;
  padding: 0;
  // overflow: hidden;
}
.cursor-pointer {
  cursor: pointer !important;
}
.d-flex {
  display: flex !important;
}
.wrap-unset {
  flex-wrap: unset;
}
.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.align-center {
  align-items: center !important;
}
.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.p-20 {
  padding: 20px;
}
.p_8 {
  padding: 8px !important;
}
.ptlr_12 {
  padding: 12px 12px 12px 12px !important;
}
.h_34 {
  height: 34px !important;
}
.color-primary {
  color: #272b39 !important;
}
.color-secondary {
  color: #f9cf66 !important;
}
.color-white {
  color: #fff !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mt-14 {
  margin-top: 14px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.p-24 {
  padding: 24px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pl-20 {
  padding-right: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.ml-10 {
  margin-left: 10px;
}
.gap-6 {
  display: flex;
  gap: 6px;
}
.pos_abs_20 {
  position: absolute !important;
  bottom: 20px !important;
  left: 50%;
  transform: translateX(-50%);
}
.ml-8 {
  margin-left: 8px !important;
}
.flex_direction_col {
  flex-direction: column !important;
}
.text_align_center {
  text-align: center !important;
}
.text_capitalize {
  text-transform: capitalize;
}
.text_uppercase {
  text-transform: uppercase;
}
.cursor_disable {
  cursor: not-allowed;
}
.user_management_dropdown_items {
  &:hover {
    background-color: #e3e9fc !important;
  }
}
.h-100 {
  height: 100%;
}
.text_blur {
  filter: blur(3.5px);
}
.active_border {
  border-radius: 8px !important;
  border: 1px solid var(--blues-blue-600, #1638b1) !important;
  background: #fff !important;
  box-shadow: 0px 4px 20px 0px rgba(56, 56, 56, 0.12) !important;
}
.cursor_pointer {
  cursor: pointer;
}
.span_highlighted_text {
  color: var(--Text-Primary, #071135);
  /* Body text/Bold/B1 Bold */
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
.filter_radio_btn {
  display: flex;
  width: 211px;
  padding: 8px 0px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  color: var(--Black, var(--blue-700, #272b39));
  span {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* Body text/B2 */
}
//for antd default button black color
.black_color_button {
  border-radius: 8px;
  background: var(--blues-blue-800, #091647) !important;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Primary-White, var(--greys-primary-white, #fff)) !important;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: var(
      --blues-blue-800,
      #091647
    ) !important; /* Same color on hover */
    color: var(--Primary-White, var(--greys-primary-white, #fff)) !important;
  }
}
.color_white {
  color: white !important;
}
.table_outer_border {
  margin-top: 28px !important;
  border-bottom: 1px solid #cecece !important;
  background: #fff !important;
  border-radius: 15px !important;
  border: 1px solid #e4e8ed !important;
}
.cancel_btn {
  border-radius: 8px;
  border: 1px solid var(--blues-blue-600, #1638b1);
  background: var(--greys-primary-white, #fff);
  display: flex;
  display: flex;
  width: 95px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span {
    color: var(--blues-blue-600, #1638b1);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.primaryBtn {
  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.043px;
  border-radius: 8px;
  border: none;
  background: #f9cf66;
  padding: 6px 16px !important;
  height: auto !important;
  &:hover {
    color: #fff !important;
  }
}
.secondaryBtn {
  color: #272b39 !important;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.043px;
  border-radius: 8px;
  padding: 6px 16px !important;
  height: auto !important;
  border: 1px solid #272b39 !important;
}
.button_inactive {
  border-radius: 8px;
  border: 1px solid var(--blues-blue-200, #c8d2f8);
  background: var(--greys-primary-white, #fff);
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  width: 166px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  span {
    color: var(--blues-blue-200, #c8d2f8);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.button_1 {
  display: flex;
  width: 166px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid var(--blues-blue-700, #122d8e);
  background: var(--greys-primary-white, #fff);
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12);
  span {
    color: var(--blues-blue-700, #122d8e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.button_no_border {
  display: flex;
  width: 166px;
  height: 40px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 5px;
  span {
    color: var(--blues-blue-800, #091647);
    text-align: center;

    /* Body text/Bold/B2 Bold */
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.ant-table-cell {
  color: #272b39 !important;
}

th {
  font-weight: 700 !important;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
//red color text for table
.Live_text {
  color: var(--states-green, #31b948);

  /* Body text/Bold/B3 Bold */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.archived_text {
  color: var(--states-red, #d03c28);

  /* Body text/Bold/B3 Bold */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.noElementBox {
  margin-top: 100px;
  .title {
    color: #122d8e;
    text-align: center;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 66.667% */
  }
  .desc {
    color: #1b2444;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px !important;
  }
  .btn {
    border-radius: 8px;
    background: #f9cf66 !important;
    height: auto !important;
    padding: 10px 20px !important;
    width: 197px !important;
    color: #151d39 !important;
    border: none !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.gray_text {
  color: var(--greys-grey-800, #858689);
  text-align: center;
  font-family: Arial !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ant-checkbox .ant-checkbox-inner {
  border-color: #9ca9d8 !important;
  background: #e4e8f6 !important;
}
.ant-checkbox .ant-checkbox-inner:hover {
  border-color: #9ca9d8 !important;
  background: #e4e8f6 !important;
}
.ant-checkbox-inner::after {
  background: #384b8e !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: #1638b1 !important;
  border: 1px solid #acbcf5 !important;
}
.ant-checkbox-checked:after {
  border: 1px solid #acbcf5 !important;
}

.ant-switch-inner {
  background: #9ca9d8 !important;
}
.ant-switch-checked .ant-switch-inner {
  background: #384b8e !important;
}
.ant-select-item {
  margin-bottom: 0px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e3e9fc !important;
}
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e3e9fc !important;
}
.ant-select-item-option-active {
  background-color: #f3f5f9 !important;
}
.ant-tooltip .ant-tooltip-arrow::before {
  background: #091647 !important;
}
.ant-tooltip-inner {
  background: #091647 !important;
}
.ant-radio-inner {
  border-color: #1638b1 !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #1638b1 !important;
  background-color: #1638b1 !important;
}
.border_right_bottom {
  border-radius: 0 8px 8px 0 !important;
}

//form input box css
.form_input_box {
  display: flex;
  height: 40px;
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--blues-blue-900, #071135);
  border: 1px solid var(--greys-grey-500, #bcc3d0);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .ant-input-number-input-wrap {
    width: 100% !important;
  }
}

.Select_Input_Form {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .ant-select-selection-item {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}
.ant-select-selection-search {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.date_picker_input {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-picker-input input {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}
.ant-select .ant-select-arrow {
  svg {
    color: #122d8e !important;
  }
}
.textfield_Input_form {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
//form input box label css wrap label in a div and add className
.input_form_label {
  color: var(--BG-Blue, #1b2444);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.w-100 {
  width: 100%;
}
.multi_select_item_block {
  border-radius: 8px;
  border: 1px solid var(--yellows-yellow-300, #f9cf66);
  background: var(--yellows-yellow-200, #fae7b6);
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  .anticon {
    font-size: 12px !important;
    display: flex !important;
    align-items: center !important;
  }
  span {
    color: var(--blues-blue-800, #091647);

    /* Body text/B2 */
    font-family: Arial;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.select_input_form .ant-select-selector {
  width: 100% !important;
  display: flex !important;
  height: 40px !important;
  justify-content: center !important;
  align-items: center !important;
  .ant-select-selection-placeholder {
    color: var(--greys-grey-600, #a7aebb);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.d-none {
  display: none !important;
}
.d_none_phone {
  display: flex;
}
.align-center-justify {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align_items_center {
  display: flex !important;
  align-items: center !important;
}
.justify_items_end {
  display: flex;
  justify-content: flex-end;
}
.justify_space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align_left {
  display: flex;
  justify-content: flex-end;
}
.align_center_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-col {
  flex-direction: column !important;
}
.transition-smooth {
  transition: all 10s ease !important;
}
//multiple select with selected item css
.multiple_select {
  .ant-select-selection-overflow {
    margin-top: -2px !important;
    margin-left: 3px !important;
  }
  .ant-select-selection-item {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }
}
.h2 {
  color: var(--blues-blue-900, #071135);
  text-align: center;
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.h4 {
  color: #071135 !important;
  font-family: Arial !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-bottom: 0px !important;
}
.normal-text {
  color: var(--blues-blue-900, #071135);
  text-align: center;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
//view more link tag
.view_more_link {
  color: var(--blues-blue-600, #1638b1);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

//popover
.popover_contentDiv {
  .popover_item_col {
    display: flex !important;
    padding: 5px 12px !important;
    align-items: center !important;
    gap: 8px;
    span {
      color: var(--greys-primary-white, #fff);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .greendot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #31b948;
    }
  }
}
.ant-popover-content {
  width: min-content !important;
}
.green_dot {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #31b948;
}
.standardBtn {
  border-radius: 8px !important;
  background: var(--greys-primary-white, #fff) !important;
  box-shadow: 0px 1px 8px -4px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #122d8e !important;

  color: #122d8e !important;
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 40px;
}

::-webkit-scrollbar {
  width: 6px !important; /* width of the entire scrollbar */
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
  border: 1px solid #acbcf5 !important;
  border-radius: 74px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #acbcf5 !important; /* color of the scroll thumb */
  border-radius: 74px !important; /* roundness of the scroll thumb */
}

//mutlitple select max count tag css
.multiple_selected_add {
  .ant-select-selection-item {
    border-radius: 8px;
    border: 1px solid var(--greys-grey-400, #cdd2dc);
    background: var(--greys-primary-white, #fff);
    padding: 14px 8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ant-tag {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    padding: 4px 8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-wrap: wrap;
  }
}
//mutliple select main div css
.multiple_select_main_div {
  border-radius: 8px;
  border: 1px solid var(--greys-grey-500, #bcc3d0);
  display: flex;
  height: 40px;
  padding: 20px 10px;
  align-items: center;
  align-self: stretch;
  .selected_item_main_div {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    padding: 4px 8px;
    align-items: center;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .additional_items {
    border-radius: 8px;
    border: 1px solid var(--yellows-yellow-300, #f9cf66);
    background: var(--yellows-yellow-200, #fae7b6);
    padding: 4px 8px;
    align-items: center;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .campaign_placeholder {
    color: var(--greys-grey-600, #a7aebb);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
}
.multi_select_modal {
  .ant-modal-title {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .search_input_field {
    border: 1px solid var(--blues-blue-200, #c8d2f8) !important;
    background: var(--greys-grey-100, #f8f9ff) !important;
    .ant-input-lg {
      background: var(--greys-grey-100, #f8f9ff) !important;
    }
    .search_icon {
      color: blue !important;
      font-size: 18 px;
    }
  }
  //content
  .content_items_main_div {
    height: 256px !important;
    border-radius: 8px;
    background: var(--greys-primary-white, #fff);
    box-shadow: 4px 0px 35px 0px #e7edf8;
    overflow-y: auto;
    .content_items_main_row {
      padding: 20px;
      .no_camp_text {
        color: var(--blues-blue-800, #091647);

        /* Body Text/B1 */
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .individual_column_active {
        height: 28px !important;
        display: flex;
        align-items: center;
        border-radius: 4px;
        margin-bottom: 4px;
        background: var(--blues-blue-100, #e3e9fc) !important;
        .title_name {
          color: var(--blues-blue-800, #091647);

          /* Body Text/B1 */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .individual_column {
        height: 28px !important;
        display: flex;
        margin-bottom: 4px;

        align-items: center;
        .title_name {
          color: var(--blues-blue-800, #091647);

          /* Body Text/B1 */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  svg {
    color: #071135 !important;
  }
}
.rejected_candidate {
  text-decoration: line-through;
}
.selected_candidate_div {
  border-radius: 4px;
  border: 1px solid #93da9f;
  background: #caf3d1;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  width: fit-content;
  color: var(--blues-blue-800, #091647);

  /* Body text/Bold/B3 Bold */
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.headerMainTitle {
  color: #fff !important;
  font-family: Arial !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: "767px") {
  .button_1_sm {
    width: 100% !important;
  }
  .button_no_border_sm {
    width: 100% !important;
  }
  .f_col_rev {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .mt-0 {
    margin-top: 0px !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .mw_overflow_x {
    max-width: 100% !important;
    overflow-x: scroll !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .d-none {
    display: flex !important;
  }
  .d_none_phone {
    display: none !important;
  }
  .align_items_right {
    display: flex !important;
    align-items: end !important;
    flex-direction: column !important;
  }
  .t_align_right {
    text-align: end !important;
  }
  .justify_space_between_resp {
    justify-content: space-between;
  }
}

/**********this style is for auto fill login and signup fileds**********/
input:-webkit-autofill,


/**********this style is for auto complete google API**********/
/******{
  ****this style is for auto complete google API**********/
.googleApiContainer {
  svg {
    display: none;
  }

  .custom-style {
    color: #091647 !important;
    font-family: Arial !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    height: 40px !important;
    border-radius: 8px !important;
    border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
    line-height: normal;
    padding: 0px 8px !important;
    width: 100%;
    &::placeholder {
      color: #a7aebb !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }
    &:focus-visible {
      outline: none !important;
    }
  }
  .google-covert {
    border-radius: 8px !important;
    background: var(--greys-primary-white, #fff) !important;
    margin-top: 10px !important;
    position: absolute;
    z-index: 100 !important;
    padding: 12px 16px !important;
    box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
    li {
      list-style-type: none !important;
      color: var(--Black, var(--blue-700, #272b39)) !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      margin-bottom: 12px !important;
      font-weight: 400;
      line-height: normal;
      img {
        display: none !important;
      }
    }
  }
}
.css-1xc3v61-indicatorContainer {
  display: none !important;
}

.css-13cymwt-control {
  border-radius: 8px !important;
  &:hover {
    border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
    border-color: #bcc3d0 !important;
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}
.css-1dimb5e-singleValue {
  color: #091647 !important;
  font-family: Arial !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.css-1jqq78o-placeholder {
  color: var(--greys-grey-600, #a7aebb) !important;
  font-family: Arial !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.css-t3ipsp-control {
  border-radius: 8px !important;
  border: 1px solid var(--greys-grey-500, #bcc3d0) !important;
  border-color: #bcc3d0 !important;
  box-shadow: none !important;
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
}
.customPagination {
  .ant-pagination-simple-pager {
    display: none !important;
  }
  .ant-pagination {
    a {
      border-radius: 6px !important;
      height: 32px !important;
      background: #fff !important;
      padding: 0px 8px !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      border: 1px solid #e4e8ed !important;
      path {
        stroke: #1b2444 !important;
      }
    }
  }
  .ant-pagination-disabled {
    a {
      border: 1px solid #acbcf5 !important;
      path {
        stroke: #acbcf5 !important;
      }
    }
  }
  padding: 15px 8px;
  .subDesc {
    color: #1638b1;

    /* Body text/B3 */
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    line-height: normal;
    span {
      color: #1b2444;
      font-weight: 700;
    }
  }
  .mt-3 {
    margin-top: -2px !important;
  }
  .pageNumbers {
    margin: 0px 0px 0px 8px;
    padding-left: 8px;
    border-left: 1px solid #e7e8f2;
  }
}
.noWarp {
  flex-wrap: nowrap !important;
  overflow: hidden !important;
}
.text-wrap-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex-wrap: nowrap !important;
}
// .ant-select-dropdown {
//   background-color: red !important;
//   ::-webkit-scrollbar {
//     width: 6px !important; /* width of the entire scrollbar */
//   }

//   ::-webkit-scrollbar-track {
//     background: transparent !important; /* color of the tracking area */
//     border: 1px solid #acbcf5 !important;
//     border-radius: 74px !important;
//   }

//   ::-webkit-scrollbar-thumb {
//     background-color: #acbcf5 !important; /* color of the scroll thumb */
//     border-radius: 74px !important; /* roundness of the scroll thumb */
//   }
// }
.errorPage {
  .title {
    color: #302c48;
    text-align: center;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 22px !important;
  }
  .desc {
    margin-top: 0px !important;
    color: #302c48;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    text-align: center !important;
    border-radius: 8px !important;
    background: var(--Yellows-Yellow-300, #f9cf66) !important;
    color: #091647 !important;
    font-family: Arial;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none !important;
    outline: none !important;
  }
}

.ant-message {
  // bottom: 40px !important;
  // top: calc(100vh - 120px) !important;
  top: unset !important;
  bottom: 40px !important;
  .ant-message-notice-success {
    .ant-message-notice-content {
      border-radius: 8px !important;
      border: 1px solid #31b948 !important;
      background: #f5fbf8 !important;
      // padding: 14px 16px !important;
      .anticon {
        display: none !important;
      }
      span {
        color: #1d8c31 !important;
        font-family: Arial !important;
        // margin-left: 10px !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        display: flex;
        align-items: center;

        &::before {
          padding-right: 6px !important;
          margin-top: 4px !important;
          // margin-right: 12px !important  ;
          content: url("../Assets/campagin/success.svg");
        }
      }
    }
  }
  .ant-message-notice-error {
    .ant-message-notice-content {
      border-radius: 8px !important;
      border: 1px solid #d03c28 !important;
      background: #fff1f4 !important;
      // padding: 14px 16px !important;
      .anticon {
        display: none !important;
      }
      span {
        color: #d03c28 !important;
        font-family: Arial !important;
        // margin-left: 10px !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        display: flex;
        align-items: center;

        &::before {
          padding-right: 6px !important;
          margin-top: 4px !important;
          // margin-right: 12px !important  ;
          content: url("../Assets/danger.svg");
        }
      }
    }
  }
}
.modal-mount .ant-drawer-mask {
  background: none !important;
}
.aboutus_container {
  ::-webkit-scrollbar {
    width: 0px !important; /* width of the entire scrollbar */
    height: 0px !important;
  }
}
.terms_of_use_container {
  ::-webkit-scrollbar {
    width: 0px !important; /* width of the entire scrollbar */
    height: 0px !important;
  }
}
