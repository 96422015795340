.add_requirement_phone_main_div {
  width: 100% !important;
  border-radius: 12px;
  border: 1px solid var(--greys-grey-300, #e4e8ed);
  background: var(--greys-grey-100, #f8f9ff);
  display: flex;
  width: 320px;
  padding: 8px;
  align-items: flex-start;
  gap: 20px;
  .tabs_main_col {
    padding: 8px !important;
    .requirement_buttons_active {
      border-radius: 12px;
      border: 1px solid var(--greys-grey-300, #e4e8ed);
      background: #091647;
      display: flex;
      width: 320px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 20px;
      span {
        color: var(--greys-primary-white, #fff);
        text-align: center;

        /* Mobile responsive/Body Text - Bold/B3 */
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .requirement_buttons {
      border-radius: 8px;
      background: var(--greys-primary-white, #fff);
      display: flex;
      width: 142px;
      height: 40px;
      padding: 10px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      span {
        color: var(--blues-blue-800, #091647);
        text-align: center;

        /* Mobile responsive/Body Text - Bold/B3 */
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
