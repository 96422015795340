.applicant_profile_main_container {
  .profile-goback {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .personal_detail_container {
    height: calc(100vh - 148px);

    overflow-y: scroll;
    border-radius: 12px;
    border: 1px solid #e4e8ed;
    padding: 24px 20px;
    background: #fff;
    .tagBox {
      border-radius: 15px;
      border: 1.5px solid #f9cf66;
      background: #fae7b6;
      color: #071135;
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      // cursor: pointer;
    }
    .name {
      color: #071135;
      width: 100%;
      text-align: center;
      font-family: Arial;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .detail_box {
      margin-top: 32px;
      .item {
        color: #071135 !important;
        font-family: Arial;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        margin: 0 !important;
        line-height: normal;
      }
      .value {
        color: #071135 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 0px;
      }
      @media screen and (max-width: 767px) {
        .value {
          font-size: 13px !important;
          margin-top: 7px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      height: auto !important;
      border-radius: 12px;
      overflow: auto !important;
      border: 1px solid #e4e8ed;
      padding: 10px !important;
      .name {
        font-size: 16px;
      }
    }
  }
  .professional_detail_container {
    height: calc(100vh - 148px);
    overflow-y: scroll;
    overflow-x: hidden;
    .mt-40 {
      margin-top: 40px;
    }

    .professional_detail_content {
      margin-top: 24px;
      .item {
        color: #071135 !important;
        font-family: Arial;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        margin: 0 !important;
        line-height: normal;
      }
      .value {
        color: #071135 !important;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        margin-top: 10px;
        margin-bottom: 0px;
      }
      .title {
        color: #071135;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px 0px 16px 0px;
      }
      .horizontalDivider {
        margin: 20px 0px !important;
      }
      .professional_experience_container {
        padding-right: 22px ;
        .professional_summary_box {
          border-radius: 12px;
          border: 1px solid #e4e8ed;
          background: #fff;
          padding: 24px 30px;
          .title {
            color: #071135;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0px 0px 16px 0px;
          }
          .desc {
            color: #071135;
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            margin: 0px 0px 30px 0px;
            line-height: normal;
          }
          @media screen and (max-width: 767px) {
            .title {
              font-size: 14px;
              margin: 0px 0px 16px 0px;
            }
            .desc {
              font-size: 12px;
              margin: 0px 0px 30px 0px;
            }
            padding: 16px;
          }
        }
      }
      // {/*************** tab-2 **********************/}
      .qualification_container {
        padding-right: 22px;
        .qualification_summary_box {
          border-radius: 12px;
          border: 1px solid #e4e8ed;
          background: #fff;
          padding: 24px 30px;
          .mt-30 {
            margin-top: 30px;
          }
        }
        .qualification_detail_box {
          border-radius: 12px;
          border: 1px solid #e4e8ed;
          background: #fff;
          padding: 24px 30px;
          margin: 10px 0;
        }
        @media screen and (max-width: 767px) {
          .civil-certificate {
            display: flex;
            flex-direction: column-reverse;
          }
          .qualification_summary_box {
            padding: 16px;
            .mt-30 {
              margin-top: -20px;
            }
          }
          .qualification_detail_box {
            padding: 16px;
            margin-top: 18px;
          }
        }
      }

      .other_detail_container {
        .titleBox {
          border-radius: 15px 15px 0px 0px;
          border-bottom: 1px solid #e4e8ed;
          background: #f8f9ff;
          color: #071135;
          padding: 20px;
          font-family: Arial;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 12px;
        }
        .detail-container {
          padding: 20px;
        }
        .citizen_container {
          border-radius: 15px;
          border: 1px solid #e4e8ed;
        }
        .additional_detail_container {
          border-radius: 15px;
          border: 1px solid #e4e8ed;
          margin-top: 20px;
        }
        .clearance_box {
          border-radius: 15px;
          border: 1px solid #e4e8ed;
          background: #fff;
          padding: 20px;
          margin-top: 20px;
        }
        @media screen and (max-width: 767px) {
          .sm-mt-10 {
            margin-top: 10px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        .item {
          font-size: 12px !important; //affecting all jobs card font
        }
        .value {
          font-size: 12px !important; //affecting all jobs card font
        }
        .sm-row-reverse {
          display: flex;
          gap: 15px;
          flex-direction: column-reverse;
        }
        .sm-mt-16 {
          margin-bottom: -50px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .qualification_container{
        padding-right: 0px !important;
      }
      height: auto !important;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}
//***********all jobs cards styles ************//
.jobPositionCard {
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #e4e8ed;
  background: #fff;
  padding: 20px 10px;
  .horizontalDivider {
    margin: 20px 0px !important;
  }
  .title {
    color: #071135;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    margin: 0 !important;
  }
  .itemRow {
    margin-bottom: 18px !important;
  }
  .currentRole {
    border-radius: 100px;
    background: #bff0be;
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    color: #236b21;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
  }
  .item {
    color: #071135;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
    span {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .value {
    color: #071135;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0px 0px 0px !important;
  }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 18px;
    }
    .value {
      font-size: 12px !important;
      margin: 10px 0px 0px 0px !important;
    }
    .item {
      color: #071135;

      font-family: Arial;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 !important;
      span {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
.radioButtons {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  .ant-radio-group {
    border-radius: 12px !important;
    border: 1px solid #e4e8ed !important;
    background: #fff !important;
    padding: 8px !important;
  }
  .ant-radio-button-wrapper {
    border: none !important;
    border-radius: 8px !important;
    padding: 10px 12px !important;
    height: auto !important;
    span {
      color: #071135 !important;
      text-align: center !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
  }
  .ant-radio-button-wrapper:not(:last-child) {
    margin-right: 16px;
  }
  .ant-radio-button-wrapper::before {
    content: "" !important;
    width: 0px !important;
  }
  .ant-radio-button-wrapper-checked {
    background: #071135 !important;
    border-radius: 8px !important;
    span {
      color: #fff !important;
    }
  }
  @media screen and (max-width: 767px) {
    .ant-radio-button-wrapper {
      border-radius: 8px !important;
      padding: 5px 12px !important;
      height: auto !important;
      span {
        font-size: 12px !important;
      }
    }
    .ant-radio-group {
      width: 100% !important;
      border-radius: 12px !important;
      border: 1px solid #e4e8ed !important;
      background: #fff !important;
      padding: 8px !important;
    }
  }
}
