.landing_campaign_page {
  .campaign_carousel {
    border-radius: 5px !important;
    .slick-dots li {
      width: 17.524px !important;
    }

    .slick-dots li button {
      background: #fffbe5 !important;
      border-radius: 4.673px !important;
      opacity: 1 !important;
    }
    .slick-dots li.slick-active {
      width: 35.049px !important;
      button {
        background: #f9cf66 !important;
      }
    }
    @media screen and (max-width: 767px) {
      width: calc(100vw - 48px) !important;
      border-radius: 25px !important;
      // border: 1px solid red;
    }
  }
  .header_carousel_item {
    border-radius: 5px !important;
    .imageContainer {
      position: relative;
      border-radius: 5px !important;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.2); /* Black see-through */
      color: #f1f1f1;
      width: 100%;
      height: 100%;
      display: flex;
      // align-items: center;
      transition: 0.5s ease;
      font-size: 20px;
      padding: 20px;
      border-radius: 5px !important;
      @media screen and (max-width: 767px) {
        border-radius: 12px !important;
      }
      .heading {
        color: #fff;
        font-family: Arial;
        font-size: 30px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .desc {
        color: #fff;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      span {
        cursor: pointer;
        color: #f9cf66;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 5px !important;
    }
    @media screen and (max-width: 767px) {
      border-radius: 12px !important;
      .imageContainer {
        border-radius: 12px !important;
      }
      img {
        border-radius: 12px !important;
      }
    }
  }
  .countCardsMainDiv {
    .countCard {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      min-height: 138px;
      .heading_title {
        color: var(--Black, var(--blue-700, #272b39));
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 100% */
      }
      .amount {
        color: var(--Black, var(--blue-700, #272b39));
        font-family: Arial;
        font-size: 54px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        /* 51.852% */
        margin-bottom: 0px !important;
      }
      .total_accepted_col {
        border-radius: 8px;
        background: var(--yellows-yellow-100, #fffbe5);
      }
    }
    .countCardSecond {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      min-height: 138px;
      .second_section_inner_card {
        border-radius: 8px;
        background: var(--yellows-yellow-100, #fffbe5);
        min-height: 110px;
        width: 168px !important;
      }
    }
    @media screen and (max-width: 990px) {
      .lg-mb-20 {
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      .heading_title {
        font-size: 12px !important;
        line-height: normal; /* 100% */
      }
      .amount {
        font-size: 20px !important;
        line-height: normal;
      }
    }
  }
}
.campaignCard {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  min-height: 85px;
  padding: 12px 14px;
  // max-width: 252px;
  .title {
    color: #272b39;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    margin: 0;
    font-weight: 400;
    line-height: 12.262px; /* 100% */
  }
  .title_mobile {
    color: #272b39;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
  .amount {
    color: #272b39;
    font-family: Arial;
    font-size: 33.107px;
    font-style: normal;
    font-weight: 700;
    margin-top: 20px !important;
    line-height: 17.167px; /* 51.852% */
    margin-bottom: 0px;
  }
  .amount_mobile {
    color: #272b39;

    /* Heading/H4 */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .detailBox {
    border-radius: 5px;
    background: #fffbe5;
    height: 100%;
    width: 100%;
    padding: 6px;
    .data {
      color: #071135;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      line-height: 16.356px; /* 127.273% */
      width: 100%;
      margin: 0;
    }
    .detail {
      color: var(--blues-blue-900, #071135);
      text-align: center;
      width: 100%;
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    .amount {
      font-size: 20px;
      margin-top: 20px !important;
    }
    .detail {
      font-size: 14px !important;
    }
  }
}
.campaignCardOne {
  background-image: url("../../Assets/campagin/cards/bg1.svg");
}
.campaignCardTwo {
  background-image: url("../../Assets/campagin/cards/bg2.svg");
}
.campaignCardThree {
  background-image: url("../../Assets/campagin/cards/bg3.svg");
}
.filterDropDown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  margin-left: 10px !important;
  min-width: 230px !important;
  max-width: 230px !important;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-radio-wrapper {
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-dropdown-menu-item {
    background-color: white !important;
  }
  .dropDown_radio_group {
    width: 100% !important;

    .filter_item_row {
      .fiter_button_dropdown_items {
        height: 30px !important;
        span {
          color: var(--Black, var(--blue-700, #272b39));

          /* Body text/B2 */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
.notificationFilterDropdown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  margin-left: 10px !important;
  // min-width: 480px;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    padding: 0px 10px 0px 10px !important;
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-radio-wrapper {
    background: #fff !important;
    &:hover {
      background: #fff !important;
    }
  }
  .ant-dropdown-menu-item {
    background-color: white !important;
  }
  .dropDown_radio_group {
    width: 100% !important;
    padding: 8px 0px;

    .filter_item_row {
      color: var(--Text-Primary, #071135);

      /* Body text/B2 */
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 4px 0px 4px 0px !important;

      .fiter_button_dropdown_items {
        height: 30px !important;
        span {
          color: var(--Black, var(--blue-700, #272b39));

          /* Body text/B2 */
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
.profileDropdown {
  border-radius: 8px !important;
  box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12) !important;
  background: #fff !important;
  // margin-left: 10px !important;
  min-width: 230px !important;
  max-width: 230px !important;
  &:hover {
    background: #fff !important;
  }
  ul {
    width: 100%;
    background: #fff !important;
    padding: 0 !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    &:hover {
      background: #fff !important;
    }
    li {
      padding: 0 !important;
    }
    .actionDropdown {
      .item {
        display: flex;
        display: flex;
        width: 131px;
        padding: 10px;
        align-items: center;
        gap: 6px;
        // border-radius: 8px 8px 0px 0px;
        border-bottom: 0.5px solid var(--greys-grey-300, #e4e8ed);
        background: var(--greys-primary-white, #fff);
        color: #071135 !important;
        cursor: pointer;
        font-family: Arial;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .logOutItem {
        color: #d03c28 !important;
        border-bottom: none !important;
      }
      .item:hover {
        background-color: #e3e9fc !important;
        // border-radius: 4px !important;
      }

      .ant-dropdown-menu-item {
        background-color: white !important;
        padding: 0 !important;
      }
    }
  }

  .ant-dropdown-menu-item {
    background-color: white !important;
  }
}
.successRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #8bd053 !important;
      border-radius: 2px;
    }
  }
}
.closeRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #f95c46 !important;
      border-radius: 2px;
    }
  }
}
.warningRow {
  td:nth-child(1) {
    label {
      border-left: 4px solid #f8c239 !important;
      border-radius: 2px;
    }
  }
}
.main_amount {
  color: var(--blues-blue-900, #071135);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
  @media screen and (max-width: 767px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 66.667% */
  }
}
.heading_title_inner {
  color: var(--blues-blue-900, #071135);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 767px) {
    color: var(--blues-blue-900, #071135);
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
