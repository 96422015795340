.aboutus_container {
  .dynamic_content_container {
    max-height: calc(100vh - 430px);
    padding-right: 8px;
    overflow: auto;
  }
  .goback {
    color: var(--blues-blue-900, #071135);

    /* Heading/H4 */
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .aboutus_banner2 {
    border-radius: 10px;
    width: 100%;
    height: 261px;
    background-image: url("../../Assets/bgimg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    // background-attachment: fixed;
    margin-bottom: 20px;
  }
  .bannerHeading {
    color: var(--Primary-White, var(--greys-primary-white, #fff));
    font-family: Arial;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 2% 0 0 2%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  .apiData {
    font-family: Arial !important;
    // padding-bottom: 20px !important;
    h2 {
      strong {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    p {
      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px; /* 156.25% */
      }
    }
  }
  @media screen and (max-width: 767px) {
    .aboutus_banner {
      height: 170px;
      border-radius: 20px;
    }
    .aboutus_banner2 {
      height: 170px;
      border-radius: 20px;
    }
    .bannerHeading {
      display: none;
    }
    .apiData {
      font-family: Arial !important;
      // padding-bottom: 15px !important;
      h2 {
        strong {
          font-size: 20px;
        }
      }
      p {
        strong {
          color: var(--blues-blue-900, #071135);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 156.25% */
        }
      }
    }
  }
}
.aboutus_banner {
  border-radius: 10px;
  width: 100%;
  height: 261px;
  background-image: url("../../Assets/bgimg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;
  margin-bottom: 20px;
}
.bannerHeading {
  color: var(--Primary-White, var(--greys-primary-white, #fff));
  font-family: Arial;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 2% 0 0 2%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 767px) {
  .aboutus_banner {
    height: 170px;
    border-radius: 20px;
  }
  .aboutus_banner2 {
    height: 170px;
    border-radius: 20px;
  }
  .bannerHeading {
    display: none;
  }
}
