.switchAccountModal {
  .usersContentSection {
    border-radius: 8px;
    background: var(--conditional-pop-over, #fff);
    box-shadow: 1px 2px 23px 0px rgba(0, 0, 0, 0.12);
    max-height: 188px !important;
    overflow-y: auto !important;
    margin: 30px 0 !important;
    .items_col {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;

      color: var(--blues-blue-900, #071135);
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
      padding: 5px 20px 5px 20px !important;
      line-height: normal;
    }

    .active {
      background: var(--blues-blue-100, #e3e9fc) !important;
    }
  }
  .ant-modal-title {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .desc {
    color: var(--Text-Primary, #071135);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .highlighted_text {
      color: var(--Text-Primary, #071135);
      font-family: Arial;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
