.campaign_detail_container {
  .go-back {
    color: #091647;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .campaign_detail_box {
    padding: 24px 30px;
    border-radius: 15px;
    border: 1px solid var(--greys-grey-300, #e4e8ed);
    background: var(--Surface-Secondary, #fff);

    .name {
      color: #272b39;
      /* Heading/H2 */
      font-family: Arial;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .deleteIcon {
      margin-left: 12px;
    }
    .detail_row {
      margin-top: 28px;
      .title {
        color: var(--greys-grey-800, #858689);
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .detail {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        word-break: break-word;
        text-transform: capitalize;
      }
      @media screen and (max-width: 767px) {
        .title {
          font-size: 12px;
        }
        .detail {
          font-size: 14px;
        }
      }
    }
  }

  .campaign-summary-heading {
    color: var(--blues-blue-900, #071135);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media screen and (max-width: 767px) {
    .campaign-summary-heading {
      font-size: 18px;
    }
    .deleteIcon {
      margin-left: 10px !important;
    }
    .campaign_detail_box {
      .name {
        color: var(--blues-blue-900, #071135);
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .campaign-avatar {
        width: 28px !important;
        height: 28px !important;
        background-color: #27a3ca !important;
        // .text {
        //   color: #fff;
        //   font-family: Arial;
        //   font-size: 24px !important;
        //   font-style: normal;
        //   font-weight: 700 !important;
        //   line-height: normal;
        // }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .lg-mt-20 {
      margin-top: 20px !important;
    }
  }
}
.campaign-avatar {
  // width: 69px !important;
  // height: 69px !important;
  // background-color: #ae81f8 !important;
  display: flex; /* Add this to enable flex layout */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  // .text {
  // color: #fff;
  // font-family: Arial;
  // margin-top: 10px;
  // font-size: 32px !important;
  // font-style: normal;
  // font-weight: 700 !important;
  // line-height: normal;
  // text-align: center;
  // // }
}

.campaign_summary_box {
  margin-top: 33px;
  h3 {
    color: #272b39;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .campaign_table_box {
    margin-top: 28px;
    border-bottom: 1px solid #cecece;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #e4e8ed !important;
    .ant-table {
      border-radius: 15px !important;
    }
    .ant-table-title {
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
    .campaignListTable {
      .ant-table-body {
        overflow: auto !important;
        max-height: calc(100vh - 797px) !important;
        @media screen and (max-width: 600px) {
          max-height: calc(100vh - 620px) !important;
        }
      }
    }
    .ant-table-title {
      background: var(--greys-grey-200, #f3f5f9);
      border-bottom: 1px solid #cecece !important;
      padding: 16px 16px !important;
    }
    th {
      background: #fff !important;
      border-bottom: 1px solid #cecece !important;
      color: var(--greys-grey-800, #858689) !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      max-width: 100px !important;
      font-weight: 700 !important;
      line-height: normal;
      text-align: center !important;
    }
    th::before {
      height: 0px !important;
    }
    tr {
      text-align: center !important;
      cursor: pointer;
    }
    td {
      background: #fff !important ;
      border-bottom: 1px solid #cecece !important;
      color: #1b2444 !important;
      font-family: Arial;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: normal;
    }
    .delete {
      color: #9ca9d8;
      text-align: center;
      cursor: not-allowed !important;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .deleteRed {
      color: #d03c28;
      text-align: center;

      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .searchBox {
      border-radius: 8px !important;
      border: 1px solid #e4e8ed !important;
      background: #fff !important;
      color: #9ca9d8 !important;
      font-family: Arial;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // max-width: 399px;
      // min-width: 300px;
      width: 100%;
      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-radius: 8px !important;
      }
      .ant-input-affix-wrapper {
        border: 1px solid #e4e8ed !important;
        background: #fff !important;
        color: #1638b1 !important;
        height: 40px !important;
        @media screen and (max-width: 767px) {
          height: 32px !important;
          // margin-right: 10px;
        }
        input {
          background: #fff !important;
          color: #1638b1 !important;
          font-family: Arial;
          font-size: 16px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .ant-input-group-addon {
        display: none !important;
      }

      .ant-checkbox .ant-checkbox-inner {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox .ant-checkbox-inner:hover {
        border-color: #9ca9d8 !important;
        background: #e4e8f6 !important;
      }
      .ant-checkbox-inner::after {
        background: #384b8e !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background: #384b8e !important;
        border-color: #384b8e !important;
      }

      .ant-switch-inner {
        background: #9ca9d8 !important;
      }
      .ant-switch-checked .ant-switch-inner {
        background: #384b8e !important;
      }
    }
    .Campaign-Report {
      color: var(--blues-blue-600, #1638b1);
      text-align: center;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .campaign-table-heading {
      font-size: 16px !important;
      font-family: Arial !important;
      text-align: initial !important;
    }
    @media screen and (max-width: 767px) {
      .campaignTableTitle {
        display: flex !important;
        flex-direction: row-reverse;
      }
      .align-end-sm {
        display: flex !important;
        gap: 15px;
        justify-content: end !important;
      }
      .campaign-table-heading {
        font-size: 12px !important;
      }
      .createBtn-sm {
        width: 100% !important;
        display: flex;
        height: 40px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--yellows-yellow-300, #f9cf66);
        color: var(--blues-blue-800, #091647);
        text-align: center;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      th {
        font-size: 12px !important;
      }
      td {
        font-size: 12px !important;
        font-weight: 400 !important;
      }
      .dp-none-sm {
        display: none !important;
      }
      .ant-table-title {
        padding: 10px !important;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: calc(100vw - 48px); //handling table responsive
    .dp-none-sm {
      display: none;
    }
  }
}
.createBtn {
  border-radius: 8px;
  background: #f9cf66 !important;
  height: auto !important;
  padding: 10px 20px !important;
  width: 170px !important;
  color: #151d39 !important;
  border: none !important;
  text-align: center;
  font-family: Arial;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.campaignDetailCardOne {
  background-image: url("../../Assets/campagin/cards/detailCardBg1.svg");
  height: 100%;
}
.campaignDetailCardTwo {
  background-image: url("../../Assets/campagin/cards/detailCardBg2.svg");
}
.campaignDetailTable {
  .ant-table-tbody {
    overflow: auto !important;
    max-height: calc(100vh - 790px) !important;
    @media screen and (max-width: 600px) {
      max-height: calc(100vh - 620px) !important;
    }
  }
}
