.candidateDeleteModal {
  .ant-modal-content {
    border-radius: 18px !important;
    border: 2.004px solid #fff !important;
    background: #fff !important;
    box-shadow: 0px 11.639703750610352px 124.50281524658203px 0px
      rgba(18, 45, 108, 0.17) !important;
    max-width: 392px !important;
    padding: 24px !important;
  }
  .ant-modal-title {
    color: #272b39;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .anticon {
    color: #272b39 !important;
  }
  .header {
    // text-align: center;
    margin: 14px 0px 30px 0px;
  }
  .title {
    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.043px;
    margin: 0px 0px 24px 0px !important;
  }
  .desc {
    color: #272b39;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px !important;
  }
  .confirmBtn {
    color: #fff !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px !important;
    height: auto !important;
    border-radius: 8px !important;
    background: #151d39 !important;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  }
  .cancelBtn {
    color: #384b8e !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px !important;
    height: auto !important;
    border-radius: 8px !important;
    border: 1px solid #384b8e !important;
    background: #fff !important;
  }
}
.updateStatusModal {
  .ant-modal-content {
    border-radius: 18px !important;
    border: 2.004px solid #fff !important;
    background: #fff !important;
    box-shadow: 0px 11.639703750610352px 124.50281524658203px 0px
      rgba(18, 45, 108, 0.17) !important;
    max-width: 392px !important;
  }
  .ant-modal-header {
    margin-bottom: 0 !important;
  }
  .ant-modal-title {
    color: #071135;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .anticon {
    color: #272b39 !important;
  }
  .header {
    // text-align: center;
    margin: 4px 0px 0px 0px;
  }
  .radioBtns {
    span {
      color: #071135 !important;
      font-family: Arial !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-radio-inner {
      border-color: #1638b1 !important;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: #1638b1 !important;
      background-color: #1638b1 !important;
    }
  }
  .radioStage {
    color: #858689;
    margin-top: 20px !important;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px !important;
  }
  .desc {
    color: #071135;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 !important;
  }
  .btnBox {
    margin-top: 24px;
  }
  .confirmBtn {
    color: #fff !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px !important;
    height: auto !important;
    border-radius: 8px !important;
    background: #071135 !important;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  }
  .cancelBtn {
    color: #1638b1 !important;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 20px !important;
    height: auto !important;
    border-radius: 8px !important;
    border: 1px solid #1638b1 !important;
    background: #fff !important;
  }
}
